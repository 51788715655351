import { Route, Routes } from 'react-router';
import AccountGeneral from 'src/pages/account';
import { useRouter } from './hooks';
import { useEffect } from 'react';
import Home from 'src/pages/dashboards/home';
import Workspace from 'src/pages/workspaces';
import Join from 'src/pages/workspaces/join';
import Users from 'src/pages/workspaces/users';
import WorkspaceGuard from 'src/providers/workspace';

function Redirect() {
  const router = useRouter()

  useEffect(() => {
    router.replace("/account")
  }, [])

  return null
}

export default function Router() {
  return (
    <Routes>
      <Route path="/dash/home" element={<WorkspaceGuard><Home /></WorkspaceGuard>} />
      <Route path="/account" element={<AccountGeneral />} />
      <Route path='/workspaces' element={<Workspace />} />
      <Route path='/workspaces/:id/invitations/:token' element={<Join />} />
      <Route path='/workspaces/:id/invitations/:token' element={<Join />} />
      <Route path='/workspaces/:id' element={<Users />} />
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
}