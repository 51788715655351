import { useEffect } from 'react';
import { useRouter } from 'src/routes/hooks';

export default function Redirect() {
  const router = useRouter();
  const nextUrlParam = new URLSearchParams({ to: window.location.pathname });

  useEffect(() => {
    router.replace(`/auth/signin?${nextUrlParam}`);
  }, []);

  return null;
}
