import { Button, Card, Dialog, DialogActions, IconButton, MenuItem, Skeleton, Stack, Tab, Table, TableBody, TableContainer, Tabs, Tooltip, Typography } from '@mui/material';
import { Container, alpha } from '@mui/system';
import { isEqual, set } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Label from 'src/components/label';
import { useSettingsContext } from 'src/components/settings';
import {
    useTable,
    getComparator,
    emptyRows,
    TableNoData,
    TableEmptyRows,
    TableHeadCustom,
    TableSelectedAction,
    TablePaginationCustom,
} from 'src/components/table';
import { WorkspaceList, useGetWorkspaceQuery, useInviteMutation, useRoleQuery, useWorkspaceUserDeleteMutation } from 'src/context/api/workspaces';
import { useLocales } from 'src/locales';
import { useParams, useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import UserTableToolbar from '../user-table-toolbar';
import UserTableFiltersResult from '../user-table-filters-result';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import UserTableRow from '../user-table-row';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form/form-provider';


export type IUserTableFilterValue = string | string[];

export type IUserTableFilters = {
    fullname: string;
    role: string[];
    status: string;
};

export type IUserItem = WorkspaceList["users"][number];

const STATUS_OPTIONS = [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'pending', label: 'Pending' },
    { value: 'banned', label: 'Banned' },
    { value: 'rejected', label: 'Rejected' }
];

const TABLE_HEAD = [
    { id: 'fullname', label: 'Name' },
    { id: 'role', label: 'Role', width: 180 },
    { id: 'status', label: 'Status', width: 100 },
    { id: '', width: 88 },
];

const defaultFilters: IUserTableFilters = {
    fullname: '',
    role: [],
    status: 'all',
};


export default function WorkspaceUsers() {
    const settings = useSettingsContext();
    const router = useRouter();
    const { t } = useLocales();
    const { id } = useParams() as { id: string };
    const role = useRoleQuery({ id: parseInt(id) });

    useEffect(() => {
        if (role.isError) {
            router.replace(paths.dashboard.root);
        }
        else if (role.data?.role === "GUEST") {
            router.replace(paths.dashboard.root);
        }
    })

    const { data, ...workspace } = useGetWorkspaceQuery({ id: parseInt(id as string) });
    const table = useTable();
    const confirm = useBoolean();
    const [deleteUser,] = useWorkspaceUserDeleteMutation();
    const inviteDialog = useBoolean(false);

    const tableData = useMemo(() => {
        if (!data) return [];
        return data.users;
    }, [data]);

    const [filters, setFilters] = useState(defaultFilters);

    const dataFiltered = applyFilter({
        inputData: tableData,
        comparator: getComparator(table.order, table.orderBy),
        filters,
    });


    const dataInPage = dataFiltered.slice(
        table.page * table.rowsPerPage,
        table.page * table.rowsPerPage + table.rowsPerPage
    );

    const denseHeight = table.dense ? 52 : 72;

    const canReset = !isEqual(defaultFilters, filters);

    const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

    const handleFilters = useCallback(
        (name: string, value: IUserTableFilterValue) => {
            table.onResetPage();
            setFilters((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [table]
    );

    const workspaceId = id;

    const handleDeleteRow = useCallback(
        (id: number, callback: () => void) => {
            deleteUser({ id: parseInt(workspaceId), userId: id }).unwrap().then(() => {
                table.onUpdatePageDeleteRow(dataInPage.length);
                callback();
                workspace.refetch();
            }).catch(console.error);
        },
        [dataInPage.length, table, tableData]
    );

    const handleDeleteRows = useCallback(() => {
        const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
        //   setTableData(deleteRows);

        table.onUpdatePageDeleteRows({
            totalRows: tableData.length,
            totalRowsInPage: dataInPage.length,
            totalRowsFiltered: dataFiltered.length,
        });
    }, [dataFiltered.length, dataInPage.length, table, tableData]);

    const handleEditRow = useCallback(
        (id: string) => {
            // router.push(paths.dashboard.user.edit(id));
        },
        [router]
    );

    const handleFilterStatus = useCallback(
        (event: React.SyntheticEvent, newValue: string) => {
            handleFilters('status', newValue);
        },
        [handleFilters]
    );

    const handleResetFilters = useCallback(() => {
        setFilters(defaultFilters);
    }, []);


    const renderTable = (
        <>
            {canReset && (
                <UserTableFiltersResult
                    filters={filters}
                    onFilters={handleFilters}
                    //
                    onResetFilters={handleResetFilters}
                    //
                    results={dataFiltered.length}
                    sx={{ p: 2.5, pt: 0 }}
                />
            )}

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <TableSelectedAction
                    dense={table.dense}
                    numSelected={table.selected.length}
                    rowCount={tableData.length}
                    onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                            checked,
                            tableData.map((row) => row.id)
                        )
                    }
                    action={
                        <Tooltip title="Delete">
                            <IconButton color="primary" onClick={confirm.onTrue}>
                                <Iconify icon="solar:trash-bin-trash-bold" />
                            </IconButton>
                        </Tooltip>
                    }
                />

                <Scrollbar>
                    <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                        <TableHeadCustom
                            order={table.order}
                            orderBy={table.orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={tableData.length}
                            numSelected={table.selected.length}
                            onSort={table.onSort}
                            onSelectAllRows={(checked) =>
                                table.onSelectAllRows(
                                    checked,
                                    tableData.map((row) => row.id)
                                )
                            }
                        />

                        <TableBody>
                            {dataFiltered
                                .slice(
                                    table.page * table.rowsPerPage,
                                    table.page * table.rowsPerPage + table.rowsPerPage
                                )
                                .map((row) => (
                                    <UserTableRow
                                        key={row.id}
                                        row={row}
                                        selected={table.selected.includes(row.id)}
                                        onSelectRow={() => table.onSelectRow(row.id)}
                                        onDeleteRow={(callback) => handleDeleteRow(row.id, callback)}
                                        onEditRow={() => handleEditRow(row.id.toString())}
                                    />
                                ))}

                            <TableEmptyRows
                                height={denseHeight}
                                emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                            />

                            <TableNoData notFound={notFound} />
                        </TableBody>
                    </Table>
                </Scrollbar>
            </TableContainer>
        </>
    )

    return (
        <>
            <Container sx={{ height: "100%" }} maxWidth={settings.themeStretch ? false : 'xl'}>
                <CustomBreadcrumbs
                    heading={t('workspaces.heading')}
                    links={[
                        { name: t('workspaces.breadcrumb'), href: "/workspaces" },
                        { name: data?.name || '', href: `/workspaces/${id}` },
                        { name: t('workspaces.settings') },
                        { name: t('workspaces.users') }
                    ]}
                    sx={{
                        mb: 0,
                    }}
                    action={
                        <Button
                            onClick={inviteDialog.onTrue}
                            variant="contained"
                            startIcon={<Iconify icon="mingcute:add-line" />}>
                            Invite new user
                        </Button>
                    }
                />

                <Card sx={{ my: 5 }}>
                    <Tabs
                        value={filters.status}
                        onChange={handleFilterStatus}
                        sx={{
                            px: 2.5,
                            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                        }}
                    >
                        {STATUS_OPTIONS.map((tab) => (
                            <Tab
                                key={tab.value}
                                iconPosition="end"
                                value={tab.value}
                                label={tab.label}
                                icon={
                                    <Label
                                        variant={
                                            ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                                        }
                                        color={
                                            (tab.value === 'active' && 'success') ||
                                            (tab.value === 'pending' && 'warning') ||
                                            (tab.value === 'banned' && 'error') ||
                                            'default'
                                        }
                                    >
                                        {tab.value === 'all' && data?.users.length}
                                        {tab.value === 'active' &&
                                            data?.users.filter((user) => user.status === 'active').length}

                                        {tab.value === 'pending' &&
                                            data?.users.filter((user) => user.status === 'pending').length}
                                        {tab.value === 'banned' &&
                                            data?.users.filter((user) => user.status === 'banned').length}
                                        {tab.value === 'rejected' &&
                                            data?.users.filter((user) => user.status === 'rejected').length}
                                    </Label>
                                }
                            />
                        ))}
                    </Tabs>

                    <UserTableToolbar
                        filters={filters}
                        onFilters={handleFilters}
                        //
                        roleOptions={["ADMIN", "EDITOR", "GUEST"]}
                    />

                    {
                        workspace.isLoading ? (
                            <Skeleton variant="rectangular" width={"100%"} animation={"wave"}>
                                {renderTable}
                            </Skeleton>
                        )
                            :
                            renderTable
                    }


                    <TablePaginationCustom
                        count={dataFiltered.length}
                        page={table.page}
                        rowsPerPage={table.rowsPerPage}
                        onPageChange={table.onChangePage}
                        onRowsPerPageChange={table.onChangeRowsPerPage}
                        //
                        dense={table.dense}
                        onChangeDense={table.onChangeDense}
                    />
                </Card>
            </Container>

            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Delete"
                content={
                    <>
                        Are you sure want to delete <strong> {table.selected.length} </strong> items?
                    </>
                }
                action={
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleDeleteRows();
                            confirm.onFalse();
                        }}
                    >
                        Delete
                    </Button>
                }
            />
            <Dialog open={inviteDialog.value} onClose={inviteDialog.onFalse}>
                <UserInviteForm workspaceId={parseInt(workspaceId)} onSuccess={inviteDialog.onFalse} onCancel={inviteDialog.onFalse} />
            </Dialog>
        </>
    )
}


// User invite form
function UserInviteForm({
    workspaceId,
    onSuccess,
    onCancel,
}: {
    workspaceId: number;
    onSuccess: () => void;
    onCancel: () => void;
}) {
    const { t } = useLocales();
    const [invite, inviteRequest] = useInviteMutation()
    const workspace = useGetWorkspaceQuery({ id: workspaceId });
    // Register yup schema
    const schema = yup.object().shape({
        email: yup.string().email().required(),
        role: yup.string().required(),
    });

    // Form methods
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
            role: '',
        },
    });

    const handleInvite = (form: { email: string, role: string }) => {
        invite({ ...form, id: workspaceId }).unwrap().then(() => {
            workspace.refetch().finally(() => {
                onSuccess();
                methods.reset();
            });
        })
            .catch(console.error);
    };

    return (
        <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleInvite)}>
            <Stack spacing={3} sx={{ p: 3 }}>
                <Typography variant="h6">{t('workspaces.invite_user')}</Typography>

                <RHFTextField fullWidth name="email" label="Email Address" />

                <RHFSelect name="role" label="Role">
                    {["ADMIN", "EDITOR", "GUEST"].map((role) => (
                        <MenuItem key={role} value={role}>
                            {role}
                        </MenuItem>
                    ))}
                </RHFSelect>

                <DialogActions>
                    <Button variant="outlined" onClick={onCancel}>
                        {t('common.cancel')}
                    </Button>

                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={inviteRequest.isLoading}
                    >
                        {t('common.invite')}
                    </LoadingButton>
                </DialogActions>
            </Stack>
        </FormProvider>
    );
}


// -------------------------------------------
function applyFilter({
    inputData,
    comparator,
    filters,
}: {
    inputData: WorkspaceList["users"];
    comparator: (a: any, b: any) => number;
    filters: IUserTableFilters;
}) {
    const { fullname, status, role } = filters;

    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (fullname) {
        inputData = inputData.filter(
            (user) => user.fullname.toLowerCase().indexOf(fullname.toLowerCase()) !== -1
        );
    }

    if (status !== 'all') {
        inputData = inputData.filter((user) => user.status === status);
    }

    if (role.length) {
        inputData = inputData.filter((user) => role.includes(user.role));
    }

    return inputData;
}