import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useGetWorkspacesQuery } from 'src/context/api/workspaces';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  store: icon('ic_app_store'),
  play: icon('ic_play'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const workspaces = useGetWorkspacesQuery(null);

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          {
            title: t('nav.workspaces.heading'),
            path: paths.workspaces.root,
            icon: ICONS.ecommerce,
            children: workspaces.data?.map((workspace) => ({
              title: workspace.name,
              path: paths.workspaces.workspace(workspace.id),
            })).concat([
              {
                title: t('nav.workspaces.all'),
                path: paths.workspaces.root,
              },
            ]),
          }
        ]
      },
      {
        subheader: '',
        items: [
          {
            title: t('nav.dashboard'),
            open: true,
            path: paths.dashboard.root,
            icon: ICONS.analytics,
            children: [
              {
                title: t('nav.home'),
                path: paths.dashboard.home,
              },
              // {
              //   title: t('nav.creative'),
              //   path: paths.dashboard.creative,
              // },
              // {
              //   title: t('nav.video'),
              //   path: paths.dashboard.video,
              // },
              // {
              //   title: t('nav.performance'),
              //   path: paths.dashboard.performance,
              // },
              // {
              //   title: t('nav.attribution'),
              //   path: paths.dashboard.attribution,
              // },
            ],
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      // {
      //   subheader: '',
      //   items: [
      //     // PRODUCT
      //     {
      //       title: t('datasources'),
      //       path: paths.dashboard.applications.connections.root,
      //       icon: (
      //         <Iconify icon={"solar:double-alt-arrow-right-bold-duotone"} sx={{ width: 1, height: 1 }} />
      //       ),
      //       // children: [
      //       //   {
      //       //     title: t('General'),
      //       //     path: paths.dashboard.applications.connections.root,
      //       //   },
      //       //   {
      //       //     title: t('connectors'),
      //       //     path: paths.dashboard.applications.connections.root,
      //       //     children: [
      //       //       {
      //       //         title: t('applications.connect.facebook'),
      //       //         path: paths.dashboard.applications.connections.app('facebook'),
      //       //       },
      //       //       {
      //       //         title: t('applications.connect.instagram'),
      //       //         // path: paths.dashboard.applications.connections.app('instagram'),
      //       //         path: '#',
      //       //         info: <Label color="info">{t('soon')}</Label>,
      //       //         disabled: true,
      //       //       },
      //       //       {
      //       //         title: t('applications.connect.google'),
      //       //         // path: paths.dashboard.applications.connections.app('google'),
      //       //         path: '#',
      //       //         info: <Label color="info">{t('soon')}</Label>,
      //       //         disabled: true,
      //       //       },
      //       //       {
      //       //         title: t('applications.connect.snapchat'),
      //       //         // path: paths.dashboard.applications.connections.app('snapchat'),
      //       //         path: '#',
      //       //         info: <Label color="info">{t('soon')}</Label>,
      //       //         disabled: true,
      //       //       },
      //       //       {
      //       //         title: t('applications.connect.tiktok'),
      //       //         // path: paths.dashboard.applications.connections.app('tiktok'),
      //       //         path: '#',
      //       //         info: <Label color="info">{t('soon')}</Label>,
      //       //         disabled: true,
      //       //       },
      //       //     ],
      //       //   },
      //       // ],
      //     },
      //   ],
      // },
    ],
    [t, workspaces.data]
  );

  return data;
}
