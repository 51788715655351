import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {DatePicker, DatePickerProps} from '@mui/x-date-pickers'
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type Props = DatePickerProps<any> & {
  name: string;
  shrink?: boolean;
  helperText?: string
};

export default function RHFDatePicker({ name, helperText, shrink, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <DatePicker
            {...field}
            slotProps={{
              textField: {
                helperText: error ? error?.message : helperText,
                InputLabelProps: { shrink: shrink === undefined ? undefined : !!shrink },
                error: !!error,
              },
            }}
            value={field.value}
            onChange={(event) => {
              field.onChange(event);
            }}
            {...other}
          />
        );
      }}
    />
  );
}
