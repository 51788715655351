import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Checkbox, FormControlLabel, MenuItem, TextField, InputAdornment, IconButton, Typography } from "@mui/material";
import Iconify from 'src/components/iconify';

const ITEMS_PER_BATCH = 100;

interface Keyword {
    search_term: string;
    total_organic_searches: any;
}

interface InfiniteScrollKeywordsProps {
    keywords: any[];
    selectedKeywords: string[];
    onKeywordChange: (keyword: Keyword) => void;
}

const InfiniteScrollKeywords: React.FC<InfiniteScrollKeywordsProps> = ({ keywords, selectedKeywords, onKeywordChange }) => {
    const [loadedKeywords, setLoadedKeywords] = useState<Keyword[]>([]);
    const [batch, setBatch] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const observer = useRef<IntersectionObserver | null>(null);

    useEffect(() => {
        loadMoreItems();
    }, [batch, searchTerm]);

    const loadMoreItems = () => {
        const filteredKeywords = keywords.filter(keyword =>
            keyword.search_term.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const startIndex = batch * ITEMS_PER_BATCH;
        const endIndex = startIndex + ITEMS_PER_BATCH;
        setLoadedKeywords(filteredKeywords.slice(0, endIndex));
    };

    const lastItemRef = useCallback((node: HTMLLIElement) => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && keywords.length > loadedKeywords.length) {
                setBatch(prevBatch => prevBatch + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [loadedKeywords, keywords.length]);

    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setBatch(0); // Reset batch to start from the beginning when search term changes
        setLoadedKeywords([]); // Clear current loaded keywords to avoid duplications
    };

    const handleClearSearch = () => {
        setSearchTerm("");
        setBatch(0);
        setLoadedKeywords([]);
    };

    return (
        <div>
            <div style={{ maxHeight: 400, overflowY: 'auto' }}>
                <TextField
                    style={{ padding: 1, width: '100%' }}
                    size="small"
                    name="search keyword"
                    label="Saisir un terme à rechercher"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    InputProps={{
                        endAdornment: (
                            <>
                                {searchTerm.length !== 0 &&
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="clear search"
                                            onClick={handleClearSearch}
                                        >
                                            <Iconify icon="mdi:times" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            </>
                        ),
                    }}
                />

                <hr style={{ width: '100%' }} />

                {loadedKeywords.length === 0 ? (
                    <Typography variant="body2" style={{ padding: 10 }}>Aucun résultat</Typography>
                ) : (
                    loadedKeywords.map((keyword, index) => (
                        <MenuItem
                            key={index}
                            value={keyword.search_term}
                            ref={index === loadedKeywords.length - 1 ? lastItemRef : undefined}
                            sx={{ display: "flex", justifyContent: "space-between" }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedKeywords.includes(keyword.search_term)}
                                        onChange={() => onKeywordChange(keyword)}
                                    />
                                }
                                label={keyword.search_term}
                            />
                            {keyword.total_organic_searches}
                        </MenuItem>
                    ))
                )}
            </div>
        </div>
    );
};

export default InfiniteScrollKeywords;
