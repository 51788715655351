import { Helmet } from 'react-helmet-async';
import WorkspaceGeneralView from 'src/sections/workspaces/view/general';
// sections

// ----------------------------------------------------------------------

export default function Workspace() {
  return (
    <>
      <Helmet>
        <title> Workspaces - General</title>
      </Helmet>
      <WorkspaceGeneralView/>
    </>
  );
}
