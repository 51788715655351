import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete';

// ----------------------------------------------------------------------


interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
}

export default function RHFAutoSuggest<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  label,
  placeholder,
  helperText,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const filter = createFilterOptions<T>();
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => {
            if (Array.isArray(newValue)) {
              // Crée une nouvelle liste en mappant chaque élément de newValue
              // Si l'élément est une chaîne, utilise split pour le transformer en tableau, sinon enveloppe l'élément dans un tableau
              const _tmp = newValue.flatMap(value => typeof value === 'string' ? value.split(",") : [value]);
              other.options = other.options.concat(_tmp)
              setValue(name, _tmp, { shouldValidate: true });
            } else {
              const _tmp = other.options.concat(typeof newValue === 'string' ? newValue.split(",") as any : [newValue])
              other.options = other.options.concat(_tmp)
              setValue(name, _tmp, { shouldValidate: true });
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params as any);
    
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push(inputValue as T);
            }
    
            return filtered;
          }}
          renderOption={(props, option) => <li {...props}>{option as any}</li>}
          renderInput={(params) => {
            return (
              <TextField
                label={label}
                placeholder={placeholder}
                error={!!error}
                helperText={error ? error?.message : helperText}
                {...params}
              />
            )
          }}
          {...other}
        />
      )}
    />
  );
}
