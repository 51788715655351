import Chart, { useChart } from 'src/components/chart';

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  categories: string[]; // Ajout de la prop categories
};

export default function ChartBar({ series, categories }: Props) {
  const chartOptions = useChart({
    chart: { stacked: true },
    stroke: { show: false },

    plotOptions: {
      bar: { horizontal: true, barHeight: '80%', columnWidth: '50%', borderRadius: 0 },
    },
    fill: {
      colors: ['#43a047', '#1e88e5', '#b7b7b7']
    },
    colors: ['#43a047', '#1e88e5', '#b7b7b7'],
    xaxis: {
      categories: categories || [], // Utilisation des catégories fournies ou un tableau vide par défaut
    },
  });

  return (
    <Chart
      dir="ltr"
      type="bar"
      series={series}
      options={chartOptions}
      height={500}
    />
  );
}
