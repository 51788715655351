import { TextField, TextFieldProps } from "@mui/material";
import { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form"


type Props = TextFieldProps & {
    name: string;
    shrink?: boolean;
};


function CustomFileUpload() {

}


export default function RHFMinimalUpload({ name, helperText, type, shrink, id, ...other }: Props) {
    const { control } = useFormContext();
    const ref = useRef<HTMLInputElement>(null)

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error, ...fieldState }, formState }) => {
                return (
                    <label htmlFor={id}>
                        <input id={id} ref={ref} type="file" hidden name={field.name} onChange={(fileEvent) => field.onChange(fileEvent.target.files?.[0])} />
                        <TextField
                            onClick={() => ref.current?.click()}
                            fullWidth
                            InputLabelProps={{ shrink: shrink === undefined ? undefined : !!shrink }}
                            value={field.value && field.value instanceof File ? field.value.name : "Browse files..."}
                            error={!!error}
                            InputProps={{ readOnly: true, sx: { cursor: "pointer" } }}
                            sx={{ cursor: "pointer" }}
                            inputProps={{ style: { cursor: "pointer" } }}
                            helperText={error ? error?.message : helperText}
                            {...other}
                        />
                    </label>
                );
            }}
        />
    )
}