import { Helmet } from "react-helmet-async";
import WorkspaceUsers from "src/sections/workspaces/view/users";
import WorskpaceUsers from "src/sections/workspaces/view/users";

export default function Users() {
    return (
      <>
        <Helmet>
          <title> Workspaces - Users</title>
        </Helmet>
        <WorkspaceUsers/>
      </>
    );
  }