import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "src/context/store";

export const useCurrentWorkspace = () => {
    const selector = createSelector(
        [(state: RootState) => state.appSettings.currentWorkspace],
        (currentWorkspace) => currentWorkspace
    );

    return useSelector(selector);
}