import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

// Création de l'API pour le tableau de bord
const dashboard = createApi({
    baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
    reducerPath: 'api.dashboard',
    tagTypes: ['dashboard'],
    endpoints: (builder) => ({
        getStatsDash: builder.query<any, { startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[] }>({
            query: ({ startDate, endDate, device, countries, keywords }) => {
                const url = `/dashboard/getStats`;
                const body = {
                  startDate,
                  endDate,
                  device,
                  countries,
                  keywords
                };

                return {
                  url,
                  method: 'POST',  // Changement de GET à POST
                  body: JSON.stringify(body),
                  headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    Accept: 'application/json',
                  },
                };
            },
        }),
        getFilters: builder.query<any, { startDate: string | null, endDate: string | null, device: string[] | null, filter: { countries: string[],keywords: string[] } }>({
            // Ajout de l'argument filter pour inclure les pays dans la requête
            query: ({ startDate, endDate, device, filter }) => {
                const queryParams = new URLSearchParams();
                if (startDate) {
                    queryParams.append('startDate', startDate);
                }
                if (endDate) {
                    queryParams.append('endDate', endDate);
                }
                if (device) {
                    device.forEach(d => {
                        queryParams.append('device', d);
                    });
                }
                if (filter?.countries) {
                    filter.countries.forEach(country => {
                        queryParams.append('countries', country);
                    });
                }
                const queryString = queryParams.toString();
                const url = `/dashboard/getFilters${queryString ? `?${queryString}` : ''}`;
                return {
                    url,
                    method: 'GET',
                    headers: {
                        'Cache-Control': 'max-age=0',
                        Accept: 'application/json'
                    },
                };
            },
        }),
    }),
});

// Export des hooks pour les requêtes
export const {
    useGetStatsDashQuery,
    useGetFiltersQuery
} = dashboard;

export default dashboard;
