import { ProviderType } from "../social";

enum Type {
    AD = "ad",
    ADSET = 'adset',
    CAMPAIGN = 'campaign',
    AD_ACCOUNT = 'ad_account',
}

export { Type as ResourceType }

export interface Resource {
    id: number;
    name: string;
    external_id: string;
    childs: (Resource[]) | null;
    parent_id: null | number;
    account_id: number;
}

export interface AdAccounts {
    [prop: string]: {
        id: number;
        name: string;
    }[]
}