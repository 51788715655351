import { Button, Checkbox, Container, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useMemo, useState } from "react";
import { useGetFiltersQuery, useGetStatsDashQuery } from "src/context/api/dashboard/api";
import ChartBar from "src/sections/charts/chart-bar";
import ChartColumnStacked from "src/sections/charts/chart-column-stacked";
import ChartDonut from "src/sections/charts/chart-donut";
import ChartMixed from "src/sections/charts/chart-mixed";
import BankingWidgetSummary from "./banking-widget-summary";
import DateRangeSelect, { useDateRangeSelect } from "src/components/generic-select/date-range-select";
import { fDate } from "src/utils/format-time";
import Iconify from "src/components/iconify";
import Skeleton from '@mui/material/Skeleton';
import InfiniteScrollKeywords from "./InfiniteScrollKeywords";
import { SplashScreen } from "src/components/loading-screen";
import { useSelector } from "react-redux";

export default function HomeView() {
    const [filterDevice, setFilterDevice] = useState<any | null>([]);
    const [filterCountry, setFilterCountry] = useState<string[]>([]);
    const [selectedKeyword, setSelectedKeyword] = useState<string[]>([]);
    const [filterKeyword, setFilterKeyword] = useState<string[]>([]);
    const theme = useTheme();

    const filterStore = useSelector((state: any) => state.filters);  // Accès à filterDate depuis le store

    enum ColorSchema {
        PRIMARY = 'primary',
        SECONDARY = 'secondary',
        ERROR = 'error',
        WARNING = 'warning',
        INFO = 'info',
        SUCCESS = 'success'
    }


    const { data: statsData, error: statsError, isFetching } = useGetStatsDashQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords
    });

    console.log("ihfifij", filterStore)

    const stats = [
        {
            title: "Brand searches",
            value: statsData?.totals.organic_searches,
            color: ColorSchema.PRIMARY
        },
        {
            title: "Total brand traffic",
            value: statsData?.totals.brand_traffic,
            color: ColorSchema.SECONDARY
        },
        {
            title: "Organic brand traffic",
            value: statsData?.totals.organic_clicks,
            color: ColorSchema.ERROR

        },
        {
            title: "Ad brand traffic",
            value: statsData?.totals.ad_clicks,
            color: ColorSchema.WARNING

        },
        {
            title: "Blended ctr",
            value: statsData?.totals.blended_ctr,
            color: ColorSchema.INFO
        },
        {
            title: "Ad cost",
            value: statsData?.totals.ad_cost,
            color: ColorSchema.SUCCESS
        },
    ]

    const BrandedTrafficDistributionChart = [
        {
            name: 'Ad Cost',
            type: 'bar',
            data: statsData?.stats?.map((item: any) => item?.total_ad_cost),
        },
        {
            name: 'Blended CTR',
            type: 'line',
            data: statsData?.stats?.map((item: any) => item?.blended_ctr),
        },

    ];

    const BlendedCTRAdCostChart = [
        { name: 'Organic Brand Traffic', data: statsData?.stats?.map((item: any) => item?.total_organic_clicks) },
        { name: 'Ad Brand Traffic', data: statsData?.stats?.map((item: any) => item?.total_ad_clicks) },
        { name: 'Uncaptured Brand Traffic', data: statsData?.stats?.map((item: any) => item?.uncaptured_brand_traffic) },
    ]

    return (
        <>
            {(!filterStore.countries || !filterStore.keywords)
                ? <SplashScreen />
                :


                <Container maxWidth="xl" sx={{ marginTop: 5 }}>
                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                        <div>
                            <h1 style={{ fontSize: '1.25rem', top: -8, position: 'relative', textTransform: 'uppercase' }}>Traffic Analysis</h1>
                        </div>





                        {/* <>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                                <Button onClick={handleResetFilters} sx={{ marginRight: 1 }} variant="contained" color="inherit">
                                    <Iconify icon="solar:reset-alt" width={24} /> Reset all filters
                                </Button>

                                <FormControl sx={{ flexShrink: 0, width: { xs: 1, md: 200 } }}>
                                    <InputLabel>Keyword</InputLabel>
                                    <Select
                                        label="Select keyword"
                                        labelId="keyword-label"
                                        multiple={true}
                                        renderValue={(selected) => {
                                            if (selected.length === filter?.keywords?.length) {
                                                return 'All';
                                            }
                                        }}
                                        value={filterKeyword}
                                        sx={{ height: '40px', marginRight: 1 }}
                                    >
                                        <div style={{ padding: 10, display: 'flex', flexDirection: "column" }}>
                                            <MenuItem key="all" value="all">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selectedKeyword.length === filter?.keywords?.length}
                                                            onChange={() => setSelectedKeyword([])} />
                                                    }
                                                    label="All"
                                                />
                                            </MenuItem>

                                            <hr style={{ width: '100%' }} />

                                            <InfiniteScrollKeywords
                                                keywords={filterKeyword}
                                                selectedKeywords={selectedKeyword}
                                                onKeywordChange={(it) => handleKeywordFilterChange(it)}
                                            />
                                        </div>
                                    </Select>
                                </FormControl>


                                {filterCountry.length !== 0 &&
                                    <FormControl
                                        sx={{
                                            flexShrink: 0,
                                            width: { xs: 1, md: 200 },
                                        }}
                                    >
                                        <InputLabel>Country</InputLabel>
                                        <Select
                                            label="Select country"
                                            labelId="country-label"
                                            multiple={true}
                                            value={filterCountry}
                                            renderValue={() => filterCountry.map((value: any) => value).join(', ')}
                                            sx={{ height: '40px', marginRight: 1 }}
                                        >
                                            <div style={{ padding: 10, display: 'flex', flexDirection: "column" }}>
                                                <MenuItem key="all" value="all">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={filterCountry.length === filter?.countries?.length}
                                                                onChange={() => handleCountryFilterChange("all")}
                                                            />
                                                        }
                                                        label="All"
                                                    />
                                                </MenuItem>
                                                <hr style={{ width: '100%' }} />

                                                {filter?.countries?.map((country: string, index: number) => (
                                                    <MenuItem key={index} value={country}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={filterCountry.includes(country)}
                                                                    onChange={() => handleCountryFilterChange(country)}
                                                                />
                                                            }
                                                            label={country}
                                                        />
                                                    </MenuItem>
                                                ))}
                                            </div>
                                        </Select>
                                    </FormControl>
                                }

                                <FormControl
                                    sx={{
                                        flexShrink: 0,
                                        width: { xs: 1, md: 200 },
                                    }}
                                >
                                    <InputLabel>Device</InputLabel>
                                    <Select
                                        label="Select device"
                                        labelId="device-label"
                                        multiple={true}
                                        value={filterDevice}
                                        renderValue={() =>
                                            filterDevice.map((value: any) =>
                                                value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
                                            ).join(', ')
                                        }
                                        sx={{ height: '40px', marginRight: 1 }}
                                    >
                                        <div style={{ padding: 10, display: 'flex', flexDirection: "column" }}>
                                            <MenuItem key={0} value={0}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            value={0}
                                                            checked={filterDevice.length === 3}
                                                            onChange={() => handleDeviceFilterChange("TOUT")}
                                                        />
                                                    }
                                                    label="All"
                                                />
                                            </MenuItem>

                                            <hr style={{ width: '100%' }} />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        value={1}
                                                        onChange={() => handleDeviceFilterChange("TABLET")}
                                                        checked={filterDevice.includes("TABLET")}
                                                    />
                                                }
                                                label="Tablet"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        value={2}
                                                        onChange={() => handleDeviceFilterChange("MOBILE")}
                                                        checked={filterDevice.includes("MOBILE")}
                                                    />
                                                }
                                                label="Mobile"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        value={3}
                                                        onChange={() => handleDeviceFilterChange("DESKTOP")}
                                                        checked={filterDevice.includes("DESKTOP")}
                                                    />
                                                }
                                                label="Desktop"
                                            />
                                        </div>

                                    </Select>
                                </FormControl>


                                <DateRangeSelect
                                    startDate={(dateRangeSelect.selected === "custom" ? dateRangeSelect.start : dateRangeSelect.preset?.start) as Date}
                                    endDate={(dateRangeSelect.selected === "custom" ? dateRangeSelect.end : dateRangeSelect.preset?.end) as Date}
                                    selected={
                                        dateRangeSelect.selected === "custom"
                                            ? `${fDate(dateRangeSelect.start)} - ${fDate(dateRangeSelect.end)}`
                                            : (dateRangeSelect.preset as any).displayName
                                    }
                                    onChangeEndDate={dateRangeSelect.onChangeEndDate}
                                    onChangeStartDate={dateRangeSelect.onChangeStartDate}
                                    onChangePreset={dateRangeSelect.onChangePreset}
                                    onChangeTimeStep={dateRangeSelect.onChangeTimeStep}
                                    preset={"last_30d"}
                                    timeStep={dateRangeSelect.step as any}
                                />
                            </div>
                        </> */}
                    </div>


                    <div>
                        <Grid container spacing={2}>
                            {stats.map((stat, index) => (
                                <Grid key={index} xs={2}>

                                    {isFetching || (!statsData && !statsError)
                                        ? <Skeleton width={'100%'} height={'110px'} style={{ borderRadius: 7 }} variant="rounded" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                        :

                                        <BankingWidgetSummary
                                            title={stat?.title}
                                            icon="eva:diagonal-arrow-left-down-fill"
                                            color={stat.color}
                                            percent={0}
                                            total={stat?.value}
                                            chart={{
                                                series: [
                                                ],
                                            }}
                                        />
                                    }
                                </Grid>
                            ))}
                        </Grid>
                    </div>


                    <div>
                        <Grid container spacing={2}>
                            <Grid xs={6}>
                                <Paper
                                    sx={{
                                        backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                        boxShadow: '0px 0px 18px 7px rgba(0, 0, 0, 0.1)',
                                        height: '500px',
                                    }}
                                >
                                    {isFetching || (!statsData && !statsError)
                                        ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 0 }} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                        : <div style={{ padding: 20 }}>
                                            <div style={{ marginBottom: 20 }}>
                                                <h2 style={{ fontSize: '.75rem', color: 'grey', textTransform: 'uppercase' }} className="uppercase">Organic traffic vs. ad traffic</h2>
                                                <h1 style={{ fontSize: '1.25rem', top: -8, position: 'relative' }}>Branded Traffic Distribution</h1>
                                            </div>

                                            <ChartColumnStacked
                                                series={BlendedCTRAdCostChart}
                                                labels={statsData?.stats.map((item: any) => item?.year_week)}
                                            />
                                        </div>
                                    }

                                </Paper>
                            </Grid>
                            <Grid xs={6}>
                                <Paper
                                    sx={{
                                        backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                        boxShadow: '0px 0px 18px 7px rgba(0, 0, 0, 0.1)',
                                        height: '500px'
                                    }}
                                >

                                    {isFetching || (!statsData && !statsError)
                                        ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 0 }} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                        : <div style={{ padding: 20 }}>
                                            <div style={{ marginBottom: 20 }}>
                                                <h2 style={{ fontSize: '.75rem', color: 'grey', textTransform: 'uppercase' }} className="uppercase">Traffic Cost</h2>
                                                <h1 style={{ fontSize: '1.25rem', top: -8, position: 'relative' }}>Blended CTR & Ad Cost</h1>
                                            </div>

                                            <ChartMixed
                                                series={BrandedTrafficDistributionChart}
                                                labels={statsData?.stats.map((item: any) => item?.year_week)}
                                            />
                                        </div>
                                    }

                                </Paper>
                            </Grid>
                        </Grid>
                    </div>

                    <div>
                        <Grid container spacing={2}>
                            <Grid xs={6}>
                                <Paper
                                    sx={{
                                        backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                        boxShadow: '0px 0px 18px 7px rgba(0, 0, 0, 0.1)',
                                        height: '450px'
                                    }}
                                >
                                    {isFetching || (!statsData && !statsError)
                                        ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 0 }} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                        : <div style={{ padding: 20 }}>

                                            <div style={{ marginBottom: 20 }}>
                                                <h2 style={{ fontSize: '.75rem', color: 'grey', textTransform: 'uppercase' }} className="uppercase">Brand Keywords</h2>
                                                <h1 style={{ fontSize: '1.25rem', top: -8, position: 'relative' }}>Top 20 Brand Keywords Traffic Potential</h1>
                                            </div>
                                            <div style={{
                                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}>
                                                <ChartDonut
                                                    labels={statsData?.keywords?.map((item: any) => item?.search_term)}
                                                    series={statsData?.keywords?.map((item: any) => Number(item?.total_organic_searches))}
                                                />
                                            </div>
                                        </div>

                                    }

                                </Paper>
                            </Grid>
                            <Grid xs={6}>
                                <Paper
                                    sx={{
                                        backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                        boxShadow: '0px 0px 18px 7px rgba(0, 0, 0, 0.1)',
                                        height: '630px'
                                    }}
                                >
                                    {isFetching || (!statsData && !statsError)
                                        ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 0 }} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                        : <div style={{ padding: 20 }}>

                                            <div style={{ marginBottom: 20 }}>
                                                <h2 style={{ fontSize: '.75rem', color: 'grey', textTransform: 'uppercase' }} className="uppercase">Brand Keywords</h2>
                                                <h1 style={{ fontSize: '1.25rem', top: -8, position: 'relative' }}>Top 20 Branded Keywords</h1>
                                            </div>

                                            <ChartBar
                                                categories={statsData?.keywords?.map((item: any) => item?.search_term)}
                                                series={
                                                    [
                                                        { name: "Organic Brand Traffic", data: statsData?.keywords?.map((item: any) => item?.total_organic_clicks) },
                                                        { name: "Paid Brand Traffic", data: statsData?.keywords?.map((item: any) => item?.total_ad_clicks) },
                                                        { name: "Uncaptured Brand Traffic", data: statsData?.keywords?.map((item: any) => item?.uncaptured_brand_traffic) },
                                                    ]
                                                }
                                            />
                                        </div>
                                    }

                                </Paper>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container spacing={2}>
                        <Grid xs={3}>
                            <Paper
                                style={{
                                }}
                                sx={{
                                    py: 3,
                                    textAlign: 'center',
                                    position: "relative",
                                    backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                    top: -170,
                                    boxShadow: '0px 0px 18px 7px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                <h4>Actual Brand Traffic</h4>
                                <h4>{stats[1].value}</h4>
                            </Paper>
                        </Grid>
                        <Grid xs={3}>
                            <Paper
                                sx={{
                                    py: 3,
                                    textAlign: 'center',
                                    position: "relative",
                                    backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                    top: -170,
                                    boxShadow: '0px 0px 18px 7px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                <h4>Blended CTR & Ad Cost</h4>
                                <h4>{statsData?.totals.uncaptured_brand_traffic}</h4>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container >
            }
        </>

    )
}