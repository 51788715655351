import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AdAccounts, Resource } from './models';
import { QueryArg, QueryByType } from './query';


const resource = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: CROSS_API_URL + '/resources',
  }),
  reducerPath: 'api.resource',
  tagTypes: ['resource'],
  endpoints: (builder) => ({
    getResources: builder.query<{ resources: Resource[] }, QueryArg>({
      query: ({ type, workspace }) => ({
        url: `/${type}/adaccounts?${new URLSearchParams({ workspace: workspace.toString() })}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    getResourcesByType: builder.query<{ resources: Resource[] }, QueryByType>({
      query: ({ type }) => ({
        url: `/${type}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    getAdAccounts: builder.query<AdAccounts, null>({
      query: () => ({
        url: `/ad_accounts`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
  }),
});

export const { useGetResourcesQuery, useGetResourcesByTypeQuery, useGetAdAccountsQuery } = resource;
export default resource;