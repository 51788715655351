import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import { useParams } from 'src/routes/hooks';
// sections
import HomeView from 'src/sections/overview/dashboards/views/dashboard';

// ----------------------------------------------------------------------

export default function Home() {
  const {t} = useLocales()
  return (
    <>
      <Helmet>
        <title>{t('home')}</title>
      </Helmet>

      <HomeView />
    </>
  );
}
