import { useCallback, useEffect, useState } from 'react';
import { SplashScreen } from 'src/components/loading-screen';
import { useCurrentUserQuery } from 'src/context/api/auth';
import Auth from 'src/pages/auth';
import { useRouter } from 'src/routes/hooks';

const signinPath = '/auth/signin';

export default function AuthGuard(props: Props) {
  const router = useRouter()
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const { data: user, isLoading, isFetching, isError, error } = useCurrentUserQuery();
  const checkAuth = useCallback(() => {
    setAuth(!error)
    setLoading(isLoading)
  }, [user, error])

  useEffect(() => {
    checkAuth()
  }, [user, error]);

  if (loading) {
    return <SplashScreen/>
  }

  if (!auth) {
    return <Auth/>
  }

  return <>{props.children}</>
}

interface Props {
  children: React.ReactNode;
}
