import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Invitation, WorkspaceList } from './models';
import fetchBaseQueryCSRF from '../helper';

interface QueryArg {
  id?: number;
  name: string;
  editors?: string[];
  logo?: File;
  datasource?: number[];
}


const workspaces = createApi({
  baseQuery: fetchBaseQueryCSRF({baseUrl: CROSS_API_URL + "/workspaces"}),
  reducerPath: 'api.workspaces',
  tagTypes: ['workspaces'],
  endpoints: (builder) => ({
    createWorkspace: builder.mutation<{ id: number }, QueryArg>({
      query: (body) => ({
        url: `/`,
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    updateWorkspace: builder.mutation<{ id: number }, QueryArg>({
      query: (body) => ({
        url: `/${body.id}`,
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    getWorkspaces: builder.query<WorkspaceList[], null>({
      query: () => ({
        url: `/`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    getWorkspace: builder.query<WorkspaceList, { id: number }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    workspaceUserUpdate: builder.mutation<WorkspaceList, { id: number, userId: number, role: string }>({
      query: ({ id, userId, role }) => ({
        url: `/${id}/users/${userId}`,
        method: 'POST',
        body: { role },
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    workspaceUserDelete: builder.mutation<WorkspaceList, { id: number, userId: number }>({
      query: ({ id, userId }) => ({
        url: `/${id}/users/${userId}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    // Get current user role in workspace
    role: builder.query<{role: string}, { id: number }>({
      query: ({ id }) => ({
        url: `/${id}/users/me`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    invitations: builder.query<Invitation[], null>({
      query: () => ({
        url: `/invitations`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    invitation: builder.query<Invitation, { id: number, token: string }>({
      query: ({ id, token }) => ({
        url: `/${id}/invitations/${token}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    invite: builder.mutation<Invitation, { id: number, email: string, role: string }>({
      query: ({ id, email, role }) => ({
        url: `/${id}/invitations`,
        method: 'POST',
        body: { email, role },
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    acceptInvitation: builder.mutation<Invitation, { id: number, token: string }>({
      query: ({ id, token }) => ({
        url: `/${id}/invitations/${token}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    declineInvitation: builder.mutation<Invitation, { id: number, token: string }>({
      query: ({ id, token }) => ({
        url: `/${id}/invitations/${token}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
  }),
});

export const {
  useCreateWorkspaceMutation,
  useGetWorkspacesQuery,
  useUpdateWorkspaceMutation,
  useInvitationQuery,
  useAcceptInvitationMutation,
  useDeclineInvitationMutation,
  useGetWorkspaceQuery,
  useWorkspaceUserDeleteMutation,
  useWorkspaceUserUpdateMutation,
  useInviteMutation,
  useInvitationsQuery,
  useRoleQuery,
  useLazyRoleQuery,
} = workspaces;
export default workspaces;
