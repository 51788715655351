import { Helmet } from "react-helmet-async";
import WorkspaceJoin from "src/sections/workspaces/view/join";

export default function Join() {
    return (
      <>
        <Helmet>
          <title> Workspaces - Join</title>
        </Helmet>
        <WorkspaceJoin/>
      </>
    );
  }