import LoadingButton from "@mui/lab/LoadingButton";
import { Avatar, Button, Card, Container, Stack, Typography } from "@mui/material";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import { useAcceptInvitationMutation, useDeclineInvitationMutation, useGetWorkspacesQuery, useInvitationQuery } from "src/context/api/workspaces";
import { useLocales } from "src/locales";
import { useParams, useRouter } from "src/routes/hooks";
import { mediaUrl } from "src/utils/media-url";

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            margin: 3, width: 75, height: 75
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export default function WorkspaceJoin() {
    const settings = useSettingsContext();
    const { t } = useLocales();
    const router = useRouter();
    const { id, token } = useParams() as { id: string, token: string };
    const { data, ...invitation } = useInvitationQuery({ id: parseInt(id as string), token: token as string });
    const [acceptInvitation, acceptInvitationRequest] = useAcceptInvitationMutation();
    const [declineInvitation, declineInvitationRequest] = useDeclineInvitationMutation();
    const workspaces = useGetWorkspacesQuery(null);

    if (invitation.isError) {
        router.replace("/workspaces");
        return null;
    }

    if (acceptInvitationRequest.isSuccess) {
        workspaces.refetch().finally(() => {
            ;
            router.replace(`/workspaces`);
        });
        return null;
    }

    if (declineInvitationRequest.isSuccess) {
        router.replace(`/workspaces`);
        return null;
    }

    return (
        <Container sx={{ height: "100%" }} maxWidth={settings.themeStretch ? false : 'xl'}>
            <CustomBreadcrumbs
                heading={t('workspaces.heading')}
                links={[
                    { name: t('workspaces.breadcrumb'), href: "/workspaces" },
                    { name: t('workspaces.join_breadcrumb') }
                ]}
                sx={{
                    mb: 0,
                }}
            />
            <Stack alignItems={"center"} width={"100%"} justifyContent={"center"} direction={"row"}>
                <Card sx={{ padding: 3 }}>
                    <Stack spacing={2} justifyContent={"center"} alignItems={"center"} direction={"column"}>
                        {
                            data?.workspace && (
                                data?.workspace.logos ? (
                                    <Avatar sx={{ width: 75, height: 75 }} src={mediaUrl(data.workspace.logos)} />
                                )
                                    :
                                    <Avatar sizes="large" {...stringAvatar(data.workspace.name)} />
                            )
                        }
                    </Stack>
                    <Stack alignItems={"center"} spacing={2} direction={"column"}>
                        <Typography variant={"h4"}>{data?.workspace.name}</Typography>
                        <Typography variant={"body1"}>{t('workspaces.join.invitedBy', { name: data?.sender.fullname || data?.sender.email, workspace: data?.workspace.name })}</Typography>
                    </Stack>
                    <Stack spacing={2} py={2} alignContent={"center"} justifyContent={"center"} direction={{ xs: 'column', md: "row" }}>
                        <LoadingButton loading={acceptInvitationRequest.isLoading} onClick={() => acceptInvitation({ id: parseInt(id), token })} variant="contained">
                            {t('workspaces.join.accept')}
                        </LoadingButton>
                        <LoadingButton loading={declineInvitationRequest.isLoading} onClick={() => declineInvitation({ id: parseInt(id), token })} variant="outlined">
                            {t('workspaces.join.decline')}
                        </LoadingButton>
                    </Stack>
                </Card>
            </Stack>
        </Container>
    )
}