import { useMemo, useState } from 'react';
import { PresetValues } from 'src/context/reducers/attribution-settings/state';

const now = new Date(Date.now());
const quarter = Math.floor(now.getMonth() / 3);

const getInitialObject = () => {
  return { start: new Date(now.getTime()), end: new Date(now.getTime()) };
};

export const presetValues: PresetValues = {
  today: { ...getInitialObject(), displayName: 'Today', step: 'day' },
  yesterday: { ...getInitialObject(), displayName: 'Yesterday', step: 'day' },
  this_week_sun_td: { ...getInitialObject(), displayName: 'This week sun. today', step: 'day' },
  this_week_mon_td: { ...getInitialObject(), displayName: 'This week mon. today', step: 'day' },
  last_week_sun_sat: { ...getInitialObject(), displayName: 'Last week sun. sat.', step: 'day' },
  last_week_mon_sun: { ...getInitialObject(), displayName: 'Last week mon. sun.', step: 'day' },
  this_month: { ...getInitialObject(), displayName: 'This month', step: 'day' },
  last_month: { ...getInitialObject(), displayName: 'Last month', step: 'day' },
  this_quarter: { ...getInitialObject(), displayName: 'This quarter', step: 'month' },
  last_7d: { ...getInitialObject(), displayName: 'Last 7 days', step: 'day' },
  last_14d: { ...getInitialObject(), displayName: 'Last 14 days', step: 'day' },
  last_28d: { ...getInitialObject(), displayName: 'Last 28 days', step: 'day' },
  last_30d: { ...getInitialObject(), displayName: 'Last 30 days', step: 'day' },
  last_90d: { ...getInitialObject(), displayName: 'Last 90 days', step: 'day' },
  this_year: { ...getInitialObject(), displayName: 'This year', step: 'month' },
};

// Basics
presetValues.yesterday.start.setDate(now.getDate() - 1);
presetValues.yesterday.end.setDate(now.getDate() - 1);
presetValues.last_7d.start.setDate(now.getDate() - 7);
presetValues.last_14d.start.setDate(now.getDate() - 14);
presetValues.last_28d.start.setDate(now.getDate() - 28);
presetValues.last_30d.start.setDate(now.getDate() - 30);
presetValues.last_90d.start.setDate(now.getDate() - 90);
// This week sun. today
presetValues.this_week_sun_td.start.setDate(now.getDate() - now.getDay());
//This week mon. today
presetValues.this_week_mon_td.start.setDate(now.getDate() - ((now.getDay() + 6) % 7));

//Last week mon. sun.
presetValues.last_week_mon_sun.end.setDate(now.getDate() - ((now.getDay() + 6) % 7) - 1);
presetValues.last_week_mon_sun.start.setDate(now.getDate() - ((now.getDay() + 6) % 7) - 7);
// Last week sun. sat.
presetValues.last_week_sun_sat.end.setDate(now.getDate() - now.getDay() - 1);
presetValues.last_week_sun_sat.start.setDate(now.getDate() - now.getDay() - 7);

// this month
presetValues.this_month.start.setDate(1);
presetValues.this_month.end.setMonth(now.getMonth() + 1);
presetValues.this_month.end.setDate(0);
presetValues.last_month.start.setMonth(now.getMonth() - 1);
presetValues.last_month.start.setDate(1);
presetValues.last_month.end.setDate(0);

// quarter
presetValues.this_quarter.start.setMonth(quarter * 3 - 3);
presetValues.this_quarter.start.setDate(1);
presetValues.this_quarter.end.setFullYear(presetValues.this_quarter.start.getFullYear());
presetValues.this_quarter.end.setMonth(presetValues.this_quarter.start.getMonth() + 3);
presetValues.this_quarter.end.setDate(0);
// full year
presetValues.this_year.start.setDate(1);
presetValues.this_year.start.setMonth(0);
presetValues.this_year.end.setMonth(0);
presetValues.this_year.end.setDate(0);
presetValues.this_year.end.setFullYear(presetValues.this_year.start.getFullYear());

export default function useDatePreset<T extends keyof PresetValues>(_preset: T) {
  const [preset, setPreset] = useState(_preset);
  const presetvalue = useMemo(() => presetValues[preset], [preset])
  return {
    ...presetvalue,
    setPreset,
    preset,
  };
}
