import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RegisterQueryArg, SigninQueryArg, UpdateAccountQueryArg } from "./query";
import { CurrentUserResponse, RegisterResponse, SigninResponse } from "./models";
import fetchBaseQueryCSRF from "../helper";

const auth = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
  reducerPath: 'api.auth',
  tagTypes: ['auth'],
  endpoints: (builder) => ({
    signin: builder.mutation<SigninResponse, SigninQueryArg>({
      query: (arg) => ({
        url: '/auth/signin',
        method: 'POST',
        body: arg,
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    signout: builder.mutation<{}, {}>({
      query: (arg) => ({
        url: '/auth/signout',
        method: 'PUT',
        body: arg,
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    currentUser: builder.query<CurrentUserResponse, void>({
      query: () => ({
        url: '/account/me',
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    updateAccount: builder.mutation<{}, UpdateAccountQueryArg>({
      query: ({ profile, address }) => ({
        url: '/account/me',
        method: 'PATCH',
        body: { profile, address },
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    register: builder.mutation<RegisterResponse, RegisterQueryArg>({
      query: (arg) => ({
        url: '/auth/register',
        method: 'POST',
        body: arg,
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    deleteAccount: builder.mutation<{}, {}>({
      query: () => ({
        url: '/account/me',
        method: 'DELETE',
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    updatePassword: builder.mutation<{}, { password: string; confirmPassword: string, currentPassword: string }>({
      query: ({ password, confirmPassword, currentPassword }) => ({
        url: '/account/me/password',
        method: 'PATCH',
        body: { password, confirm_password: confirmPassword, current_password: currentPassword },
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useSigninMutation,
  useSignoutMutation,
  useRegisterMutation,
  useCurrentUserQuery,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
  useUpdatePasswordMutation,
} = auth;
export default auth;