import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// routes
import { RouterLink } from 'src/routes/components';
import { useSearchParams, useRouter } from 'src/routes/hooks';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useCurrentUserQuery, useRegisterMutation } from 'src/context/api/auth';
import { useLocales } from 'src/locales';
import { Divider } from '@mui/material';

// ----------------------------------------------------------------------

export default function Register() {
  const [register, { error, data, status, isError }] = useRegisterMutation();
  const { t, currentLang } = useLocales();

  const router = useRouter();

  const [errorMsg, setErrorMsg] = useState('');

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('to');

  const password = useBoolean();
  const passwordConfirmation = useBoolean();
  const user = useCurrentUserQuery();

  const RegisterSchema = Yup.object().shape({
    profile: Yup.object().shape({
      firstname: Yup.string().required(t('fieldRequired', { field: 'First Name' })),
      lastname: Yup.string().required(t('fieldRequired', { field: 'Last Name' })),
    }),
    email: Yup.string()
      .required(t('fieldRequired', { field: 'Email' }))
      .email(t('Emailmustbeavalidemailaddress')),
    password: Yup.string()
      .required(t('fieldRequired', { field: "password" }))
      .test({
        test(value, ctx) {
          return this.parent.password_confirmation !== value ? ctx.createError({ path: "password_confirmation", message: t('auth.form.register.Passwords must be equals') }) : true;
        },
        message: t('auth.form.register.Passwords must be equals'),
      }),
    password_confirmation: Yup.string().required(t('fieldRequired', { field: "Password Confirmation" })),
  });

  const defaultValues = {
    profile: {
      firstname: '',
      lastname: '',
    },
    email: '',
    password: '',
    password_confirmation: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isError) {
      setErrorMsg(t('auth.' + ((error as any).data?.message || 'UnknownError')))
    }
    else if (data) {
      router.reload()
    }
  }, [error, data])

  useEffect(() => {
    if (user.data) {
      if (returnTo) {
        router.replace(returnTo);
      }
      else {
        router.reload();
      }
    }
  }, [user.data]);

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Typography variant="h4">{t("auth.form.register.createAccount")}</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> {t("auth.form.register.alreadyRegistered")} </Typography>

        <Link href={'/auth/signin' + searchParams} component={RouterLink} variant="subtitle2">
          {t("auth.form.register.signin")}
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{
        color: 'text.secondary',
        mt: 2.5,
        typography: 'caption',
        textAlign: 'center',
      }}
    >
      {t('auth.form.register.iAgreeTo') + ' '}
      <Link underline="always" color="text.primary">
        {t("auth.form.register.termsOfService")}
      </Link>
      {` ${t('and')} `}
      <Link underline="always" color="text.primary">
        {t("auth.form.register.privacyPolicy")}
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={handleSubmit(register)}>
      <Stack spacing={2.5}>
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        <Stack direction="row" justifyContent="center" spacing={2}>
          <IconButton>
            <img src="/assets/icons/socials/ic_google.svg" />
          </IconButton>
        </Stack>
        <Divider
          sx={{
            my: 2.5,
            typography: 'overline',
            color: 'text.disabled',
            '&::before, ::after': {
              borderTopStyle: 'dashed',
            },
          }}
        >
          OR
        </Divider>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="profile.firstname" label={t('auth.form.register.firstname')} />
          <RHFTextField name="profile.lastname" label={t('auth.form.register.lastname')} />
        </Stack>

        <RHFTextField name="email" label={t('auth.form.signin.Email')} />

        <RHFTextField
          shrink
          name="password"
          autoComplete="new-password"
          label={t('auth.form.signin.password')}
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          shrink
          autoComplete="new-password"
          name="password_confirmation"
          label={t('auth.form.register.passwordConfirmation')}
          type={passwordConfirmation.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={passwordConfirmation.onToggle} edge="end">
                  <Iconify
                    icon={passwordConfirmation.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t('auth.form.register.createAccountAction')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );

  return (
    <>
      {renderHead}

      {renderForm}

      {renderTerms}
    </>
  );
}
