import { Avatar, ButtonBase, IconButton, MenuItem, alpha } from "@mui/material";
import CustomPopover, { usePopover } from "src/components/custom-popover";
import { varHover } from 'src/components/animate';
import { useGetWorkspacesQuery } from "src/context/api/workspaces";
import { setCurrentWorkspace, useCurrentWorkspace } from "src/context/reducers/app-settings";
import { mediaUrl } from "src/utils/media-url";
import { m } from "framer-motion";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { storeSelectedAdAccount, storeSelectedAds, storeSelectedAdsets, storeSelectedCampaigns } from "src/context/reducers/attribution-settings";

export default function WorkspacePoppver() {
  const workspaces = useGetWorkspacesQuery(null);
  const currentWorkspace = useCurrentWorkspace();
  const popover = usePopover();
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();

  return (
    <>
      <ButtonBase
        onClick={popover.onOpen}
        sx={{
          pl: 1,
          py: 1,
          pr: 1,
          borderRadius: 1,
          typography: 'subtitle2',
          bgcolor: 'background.neutral',
        }}
      >
        {
          currentWorkspace
            ?
            <>
              <Avatar
                src={currentWorkspace.logos ? mediaUrl(currentWorkspace?.logos) : undefined}
                alt={currentWorkspace.name}
                sx={{
                  width: 25,
                  height: 25,
                  padding: 2,
                  marginRight: 1,
                  fontSize: 14,
                }}
              >
                {
                  currentWorkspace.name.split(' ').map((word: any) => word[0].toUpperCase()).join('')
                }
              </Avatar>
              {currentWorkspace.name}
            </>
            :
            'Select workspace'

        }
      </ButtonBase>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 215, p: 0 }}>
        {workspaces.data?.map((workspace, idx) => (
          <MenuItem
            key={workspace.id}
            onClick={() => {
              popover.onClose();
              setSearchParams({ workspace: workspace.id.toString() });
              dispatch(storeSelectedAdAccount(null as any));
              dispatch(storeSelectedCampaigns([]));
              dispatch(storeSelectedAdsets([]));
              dispatch(storeSelectedAds([]));
              dispatch(setCurrentWorkspace(workspace));
            }}
          >
            <Avatar
              src={workspace.logos ? mediaUrl(workspace?.logos) : undefined}
              alt={workspace.name}
              sx={{
                marginRight: 1,
                width: 30,
                height: 30,
                padding: 2,
                fontSize: 14,
              }}
            >
              {
                workspace.name.split(' ').map((word) => word[0].toUpperCase()).join('')
              }
            </Avatar>
            {workspace.name}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}