import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { SplashScreen } from "src/components/loading-screen";
import { useGetWorkspacesQuery } from "src/context/api/workspaces";
import { setCurrentWorkspace, useCurrentWorkspace } from "src/context/reducers/app-settings";
import Workflow from "src/sections/workflow/view";
import WorkspaceGeneralView from "src/sections/workspaces/view/general";

interface Props {
    children: React.ReactNode;
}

export default function WorkspaceGuard(props: Props) {
    const workspaces = useGetWorkspacesQuery(null);
    const workspace = useCurrentWorkspace();
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch();


    useEffect(() => {
        if (workspace && !searchParams.has('workspace')) {
            setSearchParams({ workspace: workspace.id.toString() })
        }
        else if (searchParams.has('workspace')) {
            const _workspace = workspaces?.data?.find((w) => w.id == Number(searchParams.get('workspace')))
            if (_workspace) {
                dispatch(setCurrentWorkspace(_workspace))
            }
        }

    }, [workspace, searchParams, workspaces])

    if (workspaces.isLoading || workspaces.isFetching || workspaces.isUninitialized) {
        return <SplashScreen />
    }

    if (!workspace) {
        return (
            <>
                <WorkspaceGeneralView />
                <Workflow />
            </>
        )
    }

    return <>{props.children}</>
} 