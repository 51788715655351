import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { TimeStep, initialState } from './state';

const attributionSettings = createSlice({
  name: 'attribution-settings',
  initialState,
  reducers: {
    selectDatePreset(state, action: PayloadAction<typeof initialState.datePreset>) {
      state.datePreset = action.payload;
    },
    storeSelectedAdAccount(state, action: PayloadAction<number>) {
      state.adAccount = action.payload;
    },
    storeSelectedCampaigns(state, action: PayloadAction<number[]>) {
      state.campaign = action.payload;
    },
    storeResourceType(state, action: PayloadAction<string>) {
      state.resourceTypeSelected = action.payload;
    },
    storeSelectedAds(state, action: PayloadAction<number[]>) {
      state.ads = action.payload;
    },
    storeSelectedAdsets(state, action: PayloadAction<number[]>) {
      state.adset = action.payload;
    },
    selectAttributionWindow(state, action: PayloadAction<[string | null, string | null]>) {
      state.attributionWindow = action.payload;
    },
    selectTimeStep(state, action: PayloadAction<TimeStep>) {
      state.timeStep = action.payload;
    },
    setStart(state, action: PayloadAction<number>) {
      state.start = action.payload;
    },
    setEnd(state, action: PayloadAction<number>) {
      state.end = action.payload;
    },
    setDateSelection(state, action: PayloadAction<'custom' | 'preset'>) {
      state.dateSelection = action.payload;
    }
  },
});

export const {
  storeSelectedCampaigns,
  selectAttributionWindow,
  storeSelectedAdsets,
  selectDatePreset,
  storeSelectedAds,
  selectTimeStep,
  storeResourceType,
  storeSelectedAdAccount,
  setStart,
  setEnd,
  setDateSelection,
} = attributionSettings.actions;

export default attributionSettings.reducer;
