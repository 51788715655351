import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { AnnualPerformanceResponse, FlowPerformanceResponse, SparkLinesResponse, TopAdsPerformanceResponse } from "./models";
import { QueryAdAccount, QueryAttribution, QueryDateRange, QueryResourceIds, QueryTimeStep } from "../shared/query";

export const performance = createApi({
    baseQuery: fetchBaseQuery({
        credentials: "include",
        baseUrl: CROSS_API_URL + "/performance",
    }),
    reducerPath: "api.performance",
    tagTypes: ["performance"],
    endpoints: (builder) => ({
        sparklines: builder.query<SparkLinesResponse, QueryAttribution & QueryDateRange & QueryResourceIds & QueryTimeStep & QueryAdAccount>({
            query: (args) => {
                let url = `/sparklines?start_date=${args.start}&end_date=${args.end}`;
                url += "&resource_ids=" + args.resourceIds.join("&resource_ids=");
                url += "&attribution_window=" + args.attributionWindow.join("&attribution_window=");
                url += "&time_step=" + args.timeStep;
                url += "&ad_account_id=" + args.adAccountId;
                return {
                    url,
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Cache-Control": "max-age=0",
                    },
                };
            },
        }),
        monthly: builder.query<AnnualPerformanceResponse, QueryAttribution & QueryDateRange & QueryResourceIds & QueryAdAccount>({
            query: (args) => {
                let url = `/monthly?start_date=${args.start}&end_date=${args.end}`;
                url += "&resource_ids=" + args.resourceIds.join("&resource_ids=");
                url += "&attribution_window=" + args.attributionWindow.join("&attribution_window=");
                url += "&ad_account_id=" + args.adAccountId;
                return {
                    url,
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Cache-Control": "max-age=0",
                    },
                };
            },
        }),
        topAds: builder.query<TopAdsPerformanceResponse, QueryAttribution & QueryDateRange & QueryAdAccount & {
            campaignId?: number|string;
        }>({
            query: (args) => {
                let url = `/top_ads?start_date=${args.start}&end_date=${args.end}`;
                url += "&ad_account_id=" + args.adAccountId;
                url += "&attribution_window=" + args.attributionWindow.join("&attribution_window=");
                if (args.campaignId) {
                    url += "&campaign_id=" + args.campaignId;
                }
                return {
                    url,
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Cache-Control": "max-age=0",
                    },
                };
            },
        }),
        flow: builder.query<FlowPerformanceResponse[], QueryAttribution & QueryDateRange & QueryAdAccount & {scope: "campaign"|"adset"}>({
            query: (args) => {
                let url = `/flow?start_date=${args.start}&end_date=${args.end}`;
                url += "&attribution_window=" + args.attributionWindow.join("&attribution_window=");
                url += "&ad_account_id=" + args.adAccountId;
                url += "&scope=" + args.scope;
                return {
                    url,
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Cache-Control": "max-age=0",
                    },
                };
            },
        }),
    }),
});


export const { useSparklinesQuery, useMonthlyQuery , useTopAdsQuery, useFlowQuery } = performance;
