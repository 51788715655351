// store.ts

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import filtersReducer from './reducers/filter-settings/filterSlice';  // Importez votre reducer pour filterDate ici
import { adCreatives, auth, media, social, resources, metrics, workspaces, adAccounts, csrf, dashboard } from './api';
import { appSettings, attributionSettings } from './reducers';
import attribution from './api/attribution/api';
import { performance } from './api/performance/api';

const reducer = combineReducers({
  [auth.reducerPath]: auth.reducer,
  [media.reducerPath]: media.reducer,
  [social.reducerPath]: social.reducer,
  [metrics.reducerPath]: metrics.reducer,
  [resources.reducerPath]: resources.reducer,
  [workspaces.reducerPath]: workspaces.reducer,
  [adAccounts.reducerPath]: adAccounts.reducer,
  [adCreatives.reducerPath]: adCreatives.reducer,
  [attribution.reducerPath]: attribution.reducer,
  [performance.reducerPath]: performance.reducer,
  [csrf.reducerPath]: csrf.reducer,
  [dashboard.reducerPath]: dashboard.reducer,

  attributionSettings: attributionSettings,
  appSettings: appSettings,

  filters: filtersReducer,  // Ajoutez votre reducer de filtersSlice ici
});

const store = configureStore({
  reducer,
  middleware: (defaultMiddleware) =>
    defaultMiddleware().concat([
      auth.middleware,
      media.middleware,
      social.middleware,
      metrics.middleware,
      resources.middleware,
      workspaces.middleware,
      adAccounts.middleware,
      adCreatives.middleware,
      attribution.middleware,
      performance.middleware,
      csrf.middleware,
      // Ajoutez le middleware pour filtersSlice ici si nécessaire
      dashboard.middleware,
    ]),
});

export default store;
export type RootState = ReturnType<typeof reducer>;
