import { Box, Button, ButtonBase, Checkbox, FormControlLabel, MenuItem, MenuList, Stack, Typography } from "@mui/material";
import CustomPopover, { usePopover } from "../custom-popover";
import Iconify from "../iconify";
import useDatePreset, { presetValues } from "src/hooks/use-date-preset";
import { useEffect, useState } from "react";
import { DateRagePicker } from "../custom-date-range-picker/custom-date-range-picker";
import { TimeStep } from "src/context/reducers/attribution-settings";

interface DatePreset {
    displayName: string;
    start: Date;
    end: Date;
    step: string;
}

interface Props {
    features?: { time_step: boolean };
    startDate: Date;
    endDate: Date;
    timeStep?: TimeStep;
    selected: string;
    preset: string;
    onChangeStartDate: (date: Date | null) => void;
    onChangeEndDate: (date: Date | null) => void;
    onChangePreset: (value: string) => void;
    onChangeTimeStep?: (value: TimeStep) => void;
}

interface Options {
    step: string;
    preset: string;
    selected?: "custom"|"preset";
    start?: Date;
    end?: Date;
}

export const useDateRangeSelect = (options: Options) => {
    const datePreset = useDatePreset(options.preset);
    const [startDate, setStartDate] = useState<Date | null>(options.start || null);
    const [endDate, setEndDate] = useState<Date | null>(options.end || null);
    const [selected, setSelected] = useState<"custom" | "preset">(options.selected || "preset");
    const [timeStep, setTimeStep] = useState<TimeStep>(options.step as TimeStep || presetValues[options.preset].step);


    const onChangePreset = (value: string) => {
        datePreset.setPreset(value);
        setTimeStep(presetValues[value].step);
        setSelected("preset");
    };

    const onChangeStartDate = (date: Date | null) => {
        setStartDate(date);
        setSelected("custom");
    }

    const onChangeEndDate = (date: Date | null) => {
        setEndDate(date);
        setSelected("custom");
    }

    const onChangeTimeStep = (value: TimeStep) => {
        setTimeStep(value);
    }

    return {
        preset: selected === "preset" ? datePreset : null,
        start: startDate,
        end: endDate,
        step: timeStep,
        selected,
        onChangeEndDate,
        onChangeStartDate,
        onChangePreset,
        onChangeTimeStep,
    }
}

export default function DateRangeSelect(props: Props) {
    const calendar = usePopover();
    const [preset, setPreset] = useState<string>(props.preset);
    const [startDate, setStartDate] = useState<Date | null>(props.startDate);
    const [endDate, setEndDate] = useState<Date | null>(props.endDate);
    const [timeStep, setTimeStep] = useState<TimeStep | null>(props.timeStep || presetValues[props.preset].step);
    const [selected, setSelected] = useState<"custom" | "preset">("preset");

    const onChangePreset = (value: string) => () => {
        setPreset(value);
        setSelected("preset");
    }

    const onChangeStartDate = (date: Date | null) => {
        setStartDate(date);
        setSelected("custom");
    }

    const onChangeEndDate = (date: Date | null) => {
        setEndDate(date);
        setSelected("custom");
    }
    

    const apply = () => {
        if (selected === 'preset') {
            props.onChangePreset(preset);
        }
        else {
            props.onChangeEndDate(endDate);
            props.onChangeStartDate(startDate);
        }
        if (props.features?.time_step === true && props.onChangeTimeStep && timeStep) {
            props.onChangeTimeStep(timeStep);
        }
        calendar.onClose();
    }

    return (
        <>
            <ButtonBase
                onClick={calendar.onOpen}
                sx={{
                    pl: 1,
                    py: 0.5,
                    pr: 0.5,
                    borderRadius: 1,
                    typography: 'subtitle2',
                    bgcolor: 'background.neutral',
                }}
            >
                {props.selected}

                <Iconify
                    width={16}
                    icon={
                        calendar.open
                            ? 'eva:arrow-ios-upward-fill'
                            : 'eva:arrow-ios-downward-fill'
                    }
                    sx={{ ml: 0.5 }}
                />
            </ButtonBase>
            <CustomPopover
                sx={{ pt: 2, px: 1, pb: 0, display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
                open={calendar.open}
                onClose={calendar.onClose}
            >
                {/* <Stack mt={2} mr={2} ml={1} mb={0}>
                    <Typography variant="subtitle2">SELECT DATE</Typography>
                    <MenuList>
                        {presetValues
                            ? Object.entries(presetValues).map(([value, preset]) => (
                                <MenuItem
                                    key={value}
                                    onClick={onChangePreset(value)}
                                    sx={{ fontSize: '13px', padding: '2px', marginBottom: 0.5 }}
                                >
                                    {preset.displayName}
                                </MenuItem>
                            ))
                            : ''}
                    </MenuList>
                </Stack> */}
                <Stack display={'flex'} flexDirection={'column'}>
                    <DateRagePicker
                        onClose={calendar.onClose}
                        variant="calendar"
                        startDate={selected === "custom" ? startDate : presetValues[preset].start}
                        endDate={selected === "custom" ? endDate : presetValues[preset].end}
                        onChangeEndDate={onChangeEndDate}
                        onChangeStartDate={onChangeStartDate}
                        presets={presetValues}
                    />
                    <Stack flexDirection={'row'} height={'100%'} spacing={2} mb={2} mt={2}>
                        {props.features?.time_step === true ? (
                            <Box>
                                <Typography>Group data: </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() => setTimeStep('day')}
                                            checked={timeStep === 'day'}
                                        />
                                    }
                                    label="Daily"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() => setTimeStep('week')}
                                            checked={timeStep === 'week'}
                                        />
                                    }
                                    label="Weekly"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() => setTimeStep('month')}
                                            checked={timeStep === 'month'}
                                        />
                                    }
                                    label="Monthly"
                                />
                            </Box>
                        ) : (
                            ''
                        )}
                        <Box flexGrow={1} />
                        <Stack alignSelf={'end'} direction={'row'} spacing={2}>
                            <Button
                                sx={{ maxHeight: '30px', my: 'auto' }}
                                variant="outlined"
                                color="inherit"
                                onClick={calendar.onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={{ maxHeight: '30px', my: 'auto' }}
                                color="primary"
                                variant="contained"
                                onClick={apply}
                            >
                                Ok
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </CustomPopover>
        </>
    )
}