import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { QueryAdAccount, QueryAttribution, QueryDateRange, QueryWorkspace } from "../shared/query";
import { TopAdsPerformance, TopCampaigns } from "./models";

type QueryArg = QueryDateRange & QueryAdAccount & QueryWorkspace & QueryAttribution;
type GroupBy = {
    groupBy: 'campaign' | 'ad';
};

const adAccounts = createApi({
    baseQuery: fetchBaseQuery({
        credentials: 'include',
        baseUrl: CROSS_API_URL + '/ad_accounts',
    }),
    reducerPath: 'api.ad_accounts',
    tagTypes: ['ad_accounts'],
    endpoints: (builder) => ({
        topAdsPerformance: builder.query<TopAdsPerformance, QueryArg>({
            query: (args) => {
                let url = `/${args.adAccountId}/top_ads?start_date=${args.start}&end_date=${args.end}&workspace=${args.workspace}`;
                url += ("&attribution_window=" + args.attributionWindow.join("&attribution_window="))
                return {
                    url,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Cache-Control': 'max-age=0',
                    },
                };
            }
        }),
        topCampaigns: builder.query<TopCampaigns, QueryArg & GroupBy>({
            query: (args) => {
                let url = `/${args.adAccountId}/top_campaigns?start_date=${args.start}&end_date=${args.end}&workspace=${args.workspace}&group_by=${args.groupBy}`;
                url += ("&attribution_window=" + args.attributionWindow.join("&attribution_window="))
                return {
                    url,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Cache-Control': 'max-age=0',
                    },
                };
            }
        }),
    }),
});

export const { useTopAdsPerformanceQuery, useTopCampaignsQuery } = adAccounts;
export default adAccounts;