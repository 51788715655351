import * as Yup from 'yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from 'src/components/hook-form';
import { useCurrentUserQuery, useUpdateAccountMutation } from 'src/context/api/auth';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { countries } from 'src/assets/data';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function AccountGeneralSettings() {
  const [update, response] = useUpdateAccountMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { data: user } = useCurrentUserQuery();

  const UpdateUserSchema = Yup.object().shape({
    profile: Yup.object().shape({
      id: Yup.number().nullable(),
      firstname: Yup.string(),
      lastname: Yup.string(),
      phone: Yup.string().required('Phone number is required'),
      job: Yup.string().nullable(),
    }),
    address: Yup.object().shape(
      {
        id: Yup.number().nullable(),
        city: Yup.string().optional(),
        country: Yup.string().optional(),
      }
    ),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const defaultValues = {
    profile: {
      id: user?.profile?.id || null,
      firstname: user?.profile?.firstname || '',
      lastname: user?.profile?.lastname || '',
      phone: user?.profile?.phone || '',
      job: user?.profile?.job || '',
    },
    address: {
      id: user?.addresses?.[0]?.id || null,
      city: user?.addresses?.[0]?.city || '',
      country: user?.addresses?.[0]?.country || '',
    },
    email: user?.email || '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    setValue('profile.id', user?.profile?.id || undefined);
    setValue('profile.firstname', user?.profile?.firstname || '');
    setValue('profile.lastname', user?.profile?.lastname || '');
    setValue('address.id', user?.addresses?.[0]?.id || undefined);
    setValue('address.country', user?.addresses?.[0]?.country || '');
    setValue('address.city', user?.addresses?.[0]?.city || '');
    setValue('profile.phone', user?.profile?.phone || '');
    setValue('profile.job', user?.profile?.job || '');
    setValue('email', user?.email || '');
  }, [user]);

  useEffect(() => {
    try {
      if (!response.isError && response.status === QueryStatus.fulfilled) {
        enqueueSnackbar('Update success!');
      } else if (response.isError) {
        enqueueSnackbar<'error'>('Cannot update', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  }, [response]);

  return (

    <FormProvider autoComplete="off" methods={methods} onSubmit={handleSubmit(update)}>
      <input type="hidden" {...methods.register('profile.id')} />
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        <RHFTextField name="profile.firstname" label="First name" />
        <RHFTextField name="profile.lastname" label="Last name" />
        <RHFTextField disabled name="email" label="Email Address" />
        <RHFTextField name="profile.phone" label="Phone Number" />
        <RHFAutocomplete
          name="address.country"
          label="Country"
          options={countries.map((country) => country.label)}
          getOptionLabel={(option) => option}
          renderOption={(props, option) => {
            const { code, label, phone } = countries.filter(
              (country) => country.label === option
            )[0] || {};

            if (!label) {
              return null;
            }

            return (
              <li {...props} key={label}>
                <Iconify
                  key={label}
                  icon={`circle-flags:${code.toLowerCase()}`}
                  width={28}
                  sx={{ mr: 1 }}
                />
                {label} ({code}) +{phone}
              </li>
            );
          }}
        />
        <RHFTextField name="address.city" label="City" />
        <RHFTextField name="profile.job" label="Job" />
      </Box>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>

        <LoadingButton sx={{ mt: 3 }} type="submit" variant="contained" loading={isSubmitting}>
          Save Changes
        </LoadingButton>
      </Stack>
    </FormProvider>
  )
}