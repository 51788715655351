// filtersSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilterState {
    start: Date;
    end: Date;
    devices: string[]; // Type ajusté en fonction de votre structure de données pour les appareils
    countries: string[];
    keywords: string[];
}

const initialState: FilterState = {
    start: new Date(),
    end: new Date(),
    devices: [], // Initialisation avec un tableau vide
    countries: [], // Initialisation avec un tableau vide
    keywords: [], // Initialisation avec un tableau vide
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFilterDate: (state, action: PayloadAction<{ start: Date; end: Date }>) => {
            state.start = action.payload.start;
            state.end = action.payload.end;
        },
        setFilterDevice: (state, action: PayloadAction<string[]>) => {
            state.devices = action.payload;
        },
        setFilterCountry: (state, action: PayloadAction<string[]>) => {
            state.countries = action.payload;
        },
        setFilterKeyword: (state, action: PayloadAction<string[]>) => {
            state.keywords = action.payload;
        },
    },
});

export const { setFilterDate, setFilterDevice, setFilterCountry, setFilterKeyword } = filtersSlice.actions;

export default filtersSlice.reducer;
