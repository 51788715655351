import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button, CircularProgress, FormControl } from "@mui/material";
import { Stack } from "@mui/system";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { RHFAutosuggest, RHFTextField, RHFAutocomplete } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/form-provider";
import RHFMinimalUpload from "src/components/hook-form/rhf-minimal-upload";
import { useUploadMutation } from "src/context/api/medias";
import { useGetAdAccountsQuery } from "src/context/api/resources";

import { ErrorResponse } from "src/context/api/shared/models";
import { useCreateWorkspaceMutation, useUpdateWorkspaceMutation } from "src/context/api/workspaces";
import { useLocales } from "src/locales";
import * as Yup from "yup"

interface Data {
    id?: number;
    name: string;
    editors: string[];
    datasource?: any[];
    logo?: File
}

interface DatasourceOption {
    label: string;
    value: number;
    isUserOwned: boolean;
}

interface Props {
    onCancel?: () => void;
    onSuccess?: () => void;
    values?: Data;
    datasource?: {
        id: number, name: string;
        isUserOwned: boolean;
    }[];
}

export default function WorkspaceCreate({ onCancel, onSuccess, values, datasource }: Props) {
    const { t } = useLocales();
    const schema = Yup.object({
        id: Yup.number().optional(),
        name: Yup.string().required(t("workspaces.form.create.name_required")),
        editors: Yup.array(Yup.string().required(t("workspaces.form.create.register_editor"))).required(t("workspaces.form.create.register_editor")),
        logo: Yup.mixed<File>().optional().test('isFile', t("workspaces.form.logo_must_be_valid_file"), (value) => {
            return value !== undefined ? value instanceof File : true
        }).test('tooLarge', t('workspaces.form.logo_must_be_under_2mb'), (value) => {
            return value !== undefined ? (value as File).size <= 2 * Math.pow(1000, 2) : true;
        }),
        datasource: Yup.array(Yup.mixed()).optional()
    });
    const defaultValues = useMemo(() => {
        if (values) {
            const { id, name, editors, datasource } = values
            return {
                id,
                logo: values.logo,
                name,
                editors,
                datasource: datasource?.map((datasource) => {
                    return {
                        value: datasource.id,
                        label: datasource.name,
                        isUserOwned: datasource.isUserOwned,
                    }
                }),

            }
        }
        return null
    }, [values]);
    const methods = useForm({
        defaultValues: defaultValues || {
            name: "",
            editors: [] as string[],
        },
        resolver: yupResolver(schema)
    });
    const [page, setPage] = useState<string | null>(null)
    const adAccounts = useGetAdAccountsQuery(null)
    const [create, workspace] = useCreateWorkspaceMutation()
    const [update, updateWorkspace] = useUpdateWorkspaceMutation()
    const [upload, media] = useUploadMutation()

    const onSubmit = (form: Data) => {
        const { logo, ...workspace } = form

        workspace.datasource = workspace.datasource?.map(({ value }) => value)

        const request = workspace.id ? update(workspace).unwrap() : create(workspace).unwrap()

        if (logo) {
            request.then((res) => {
                const formData = new FormData()
                formData.append("file", logo)
                formData.append("file_name", _.kebabCase(logo.name) + Date.now() + '.' + logo.name.split('.').pop())
                formData.append("resource_type", "workspace")
                formData.append("resource_id", res.id.toString())
                upload(formData)
            })
        }
    }
    const options = useMemo(() => {
        const currentUserAdAccounts = adAccounts.data?.facebook?.map((account) => {
            return {
                label: account.name,
                value: account.id,
                isUserOwned: true
            } as DatasourceOption
        }) || []
        const otherAdAccounts = datasource?.map((account) => {
            return {
                label: account.name,
                value: account.id,
                isUserOwned: account.isUserOwned,
            } as DatasourceOption
        }) || []

        return [...otherAdAccounts, ...currentUserAdAccounts.filter(current => !otherAdAccounts.some(other => other.value === current.value))]
    }, [adAccounts.data, datasource])

    useEffect(() => {
        if (workspace.isSuccess && onSuccess) {
            onSuccess()
        }
    }, [media.isSuccess, workspace.isSuccess])

    const [error, status] = useMemo(() => {
        let err = null;
        if (workspace.isError) {
            err = workspace.error
        }
        if (media.isError) {
            err = media.error
        }

        if (err && 'data' in err) {
            return [err.data as ErrorResponse, err.status]
        }
        else if (err && 'error' in err) {
            return [err.data as any, err.status]
        }
        else {
            return [err, err?.code]
        }
    }, [workspace.isError, media.isError])

    const getErrorMessage = () => {
        return 'message' in error && typeof error.message === "string" ? error.message : 'Unknown error occured'
    }

    return (
        <FormProvider autoComplete="off" methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
            {status === 400 && (
                <Alert severity="error">
                    Check the form for errors
                </Alert>
            )}
            {status === 500 && (
                <Alert severity="error">
                    Unexpected error has occured
                </Alert>
            )}
            {status && !([500, 400] as any[]).includes(status) && error && (
                <Alert severity="error">
                    {
                        getErrorMessage()
                    }
                </Alert>
            )}
            <Stack padding={1} spacing={3}>
                <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                    <RHFTextField fullWidth label={"Workspace Name"} name="name" />
                    <Box flexGrow={1} />
                </Stack>
                <Stack spacing={2} flex={1} direction={{ xs: "column", md: "row" }}>
                    <RHFAutosuggest limitTags={1} fullWidth freeSolo multiple options={[]} label={"Add Editors"} name="editors" />
                    {
                        adAccounts.isLoading ?
                            <CircularProgress size={24} />
                            :
                            <RHFAutocomplete label="Datasource" isOptionEqualToValue={(opt, value) => opt.value === value.value} multiple fullWidth name="datasource" options={options}
                                groupBy={(option) => option.isUserOwned ? "My accounts" : "Already connected"}
                            />
                    }
                </Stack>
                <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                    <FormControl>
                        <RHFMinimalUpload shrink label={"Add logo"} id="upload-logo" name="logo" />
                    </FormControl>
                </Stack>
                <Stack spacing={2} alignItems={"end"} py={3} direction={{ xs: "column-reverse", md: "row-reverse" }}>
                    <Button onClick={onCancel}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={workspace.isLoading || media.isLoading || updateWorkspace.isLoading}
                        variant="contained"
                        type="submit"
                    >
                        {values?.id ? "Update" : "Create"}
                    </LoadingButton>
                </Stack>
            </Stack>
        </FormProvider>
    )
}