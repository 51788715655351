import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const social = createApi({
    baseQuery: fetchBaseQuery({
      credentials: 'include',
      baseUrl: CROSS_API_URL + '/social',
    }),
    reducerPath: 'api.social',
    tagTypes: ['social'],
    endpoints: (builder) => ({
      getProviders: builder.query<{ providers: string[] }, {}>({
        query: () => ({
          url: `/`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        }),
      }),
    }),
  });
  
  export const { useGetProvidersQuery } = social;
  export default social;