import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------

type Props = {
  series: number[];
  labels: string[];
};

export default function ChartDonut({ series, labels }: Props) {
  // Convertir les séries en nombres
  const numericSeries = (series || []).map(item => Number(item));
  const total = numericSeries.reduce((acc, val) => acc + val, 0);

  // Fonction pour éclaircir une couleur en fonction de la luminosité
  const lightenColor = (color: string, lightness: number, maxLightness: number) => {
    const hslColor = rgbToHsl(color);
    const newLightness = hslColor.l + lightness; // Ajouter la valeur de luminosité
    const finalLightness = Math.min(newLightness, maxLightness); // Limiter la luminosité au seuil maximal
    const newHslColor = `hsl(${hslColor.h}, ${hslColor.s}%, ${finalLightness}%)`;
    return newHslColor;
  };

  // Générer un tableau de couleurs allant du rouge foncé au rouge clair
  const generateRedShades = (numShades: number, maxLightness: number) => {
    const baseColor = "#f44336"; // Rouge foncé
    const shades = [];
    for (let i = 0; i < numShades; i++) {
      const ratio = i / (numShades - 1);
      const shade = lightenColor(baseColor, ratio * 100, maxLightness); // Utiliser la luminosité comme valeur d'entrée
      shades.push(shade);
    }
    return shades;
  };



  // Fonction pour convertir une couleur RGB en HSL
  const rgbToHsl = (color: string) => {
    const hex = (c: string) => parseInt(c, 16);
    const r = hex(color.substring(1, 3)) / 255;
    const g = hex(color.substring(3, 5)) / 255;
    const b = hex(color.substring(5, 7)) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    const l = (max + min) / 2;

    if (max !== min) {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }

    return { h: Math.round(h * 360), s: Math.round(s * 100), l: Math.round(l * 100) };
  };

  const redShades = generateRedShades(numericSeries.length, 90);

  const chartOptions = useChart({
    labels: labels || [],
    colors: redShades,
    stroke: {
      show: false,
    },
    legend: {
      horizontalAlign: 'center',
      position: "right"
    },
    tooltip: {
      shared: true,
      fillSeriesColor: false,
      intersect: false,
      y: {
        formatter: (value: number) => {
          if (typeof value !== 'undefined') {
            const percentage = ((value / total) * 100).toFixed(2);
            return `${value} (${percentage}%)`;
          }
          return value;
        }
      }
    },
    

  });

  return <Chart dir="ltr" type="donut" series={numericSeries} options={chartOptions} width={550} />;
}
