import { FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BaseQueryApi } from "@reduxjs/toolkit/query";
import { RootState } from "../store";
import csrf from "./csrf/api";

const fetchBaseQueryCSRF = (opts: any) => (args: FetchArgs, api: BaseQueryApi, extraOptions: any) => {
    const state = api.getState() as RootState;
    const otherData = csrf.endpoints.getCSRF.select()(state);
  
    const modifiedArgs = {
      ...args,
      // Ajoutez ou modifiez les paramètres de la requête en fonction de otherData
      headers: {
        ...args.headers,
        'X-CSRFToken': otherData.data,
      },
    };

    return fetchBaseQuery(opts)(modifiedArgs, api, extraOptions);
}

export default fetchBaseQueryCSRF