import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const csrf = createApi({
    baseQuery: fetchBaseQuery({
        credentials: 'include',
        baseUrl: CROSS_API_URL,
    }),
    reducerPath: 'api.csrf',
    tagTypes: ['csrf'],
    endpoints: (builder) => ({
        getCSRF: builder.query<string, void>({
            query: (arg) => ({
                url: '/csrf',
                responseHandler: 'text',
                method: 'GET',
                body: arg,
                headers: {
                    'Cache-Control': 'max-age=0',
                    Accept: 'text/plain',
                },
            }),
        }),
    }),
});

export const {
    useGetCSRFQuery
} = csrf;
export default csrf;

