import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// types
import { IUserItem } from './view/users';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useGetWorkspaceQuery, useWorkspaceUserUpdateMutation } from 'src/context/api/workspaces';
import { useParams } from 'src/routes/hooks';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  currentUser?: IUserItem;
};

export default function UserQuickEditForm({ currentUser, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();
  const { id } = useParams() as { id: string };

  const [updateUser, updateUserRequestData] = useWorkspaceUserUpdateMutation()
  const workspaces = useGetWorkspaceQuery({id: parseInt(id)});

  const NewUserSchema = Yup.object().shape({
    id: Yup.number().required('ID is required'),
    firstname: Yup.string().optional(),
    lastname: Yup.string().optional(),
    email: Yup.string().required("Email is required"),
    role: Yup.string().required('Role is required'),
  });

  const defaultValues = useMemo(
    () => ({
      id: currentUser?.id || 0,
      firstname: currentUser?.firstname || '',
      email: currentUser?.email || '',
      lastname: currentUser?.lastname || '',
      role: currentUser?.role || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateUser({ id: parseInt(id), userId: data.id, role: data.role }).unwrap().then((data) => {
        workspaces.refetch().finally(() => {
          onClose();
          reset();
        });
      })
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Quick Update</DialogTitle>
        <input type="hidden" name="id" />
        <DialogContent>
          {
            updateUserRequestData.error && 'status' in updateUserRequestData?.error && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {
                  updateUserRequestData.error.status === 400
                    ? t("error.BadRequest")
                    : t("error.InternalServerError")
                }
              </Alert>
            )
          }
          <Box
            pt={3}
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >


            <RHFTextField disabled name="firstname" label="First Name" />
            <RHFTextField disabled name="lastname" label="Last Name" />
          </Box>
          <Box py={3}
            rowGap={3}
            columnGap={2}>
            <RHFTextField disabled fullWidth name="email" label="Email Address" />
          </Box>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFSelect name="role" label="Role">
              {["ADMIN", "EDITOR", "GUEST"].map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </RHFSelect>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Update
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
