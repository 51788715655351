import { Route, Routes } from 'react-router';
import Signin from './signin';
import Redirect from './redirect';
import Register from './register';
import AuthClassicLayout from 'src/layouts/auth/classic';
import ForgotPassword from './forgot-password';

export default function Auth() {
  return (
    <AuthClassicLayout>
      <Routes>
        <Route path="/auth/signin" element={<Signin />} />
        <Route path="/auth/register" element={<Register />} />
        <Route path="/auth/forgot" element={<ForgotPassword />} />
        <Route path="*" element={<Redirect />} />
      </Routes>
    </AuthClassicLayout>
  );
}
