import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
//
import { HEADER, NAV } from '../config-layout';
import {
  AccountPopover,
  SettingsButton,
  LanguagePopover,
  NotificationsPopover,
} from '../_common';
import WorkspacePoppver from '../_common/workspace-popover';
import { useDateRangeSelect } from 'src/components/generic-select/date-range-select';
import { useEffect, useMemo, useState } from 'react';
import Iconify from 'src/components/iconify';
import DateRangeSelect from 'src/components/generic-select/date-range-select';
import { fDate } from 'src/utils/format-time';
import { useGetFiltersQuery } from 'src/context/api/dashboard/api';
import { useDispatch, useSelector } from "react-redux";
import { setFilterDate, setFilterDevice, setFilterCountry, setFilterKeyword } from '../../context/reducers/filter-settings/filterSlice';
import InfiniteScrollKeywords from "src/sections/overview/dashboards/views/InfiniteScrollKeywords";
// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const [filterDevice, setFilterDeviceState] = useState<any | null>([]);
  const [filterCountry, setFilterCountryState] = useState<string[]>([]); // Updated state and setter
  const [selectedKeyword, setSelectedKeywordState] = useState<string[]>([]);
  const [filterKeyword, setFilterKeywordState] = useState<string[]>([]);
  const settings = useSettingsContext();
  const dispatch = useDispatch()
  const filterStore = useSelector((state: any) => state.filters);  // Accès à filterDate depuis le store

  const [endDate, setEndDate] = useState<any>(); // Updated state and setter


  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  // Initialisation des valeurs de date
  const [start, end] = useMemo(() => {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    startDate.setDate(startDate.getDate() + 1);
    const endDate = new Date();
    endDate.setDate(endDate.getDate() - 1);
    return [startDate, endDate];
  }, []);

  const dateRangeSelect = useDateRangeSelect({
    preset: "last_30d",
    step: "month",
    selected: "custom",
    start,
    end
  });

  // Fonction pour mettre à jour la plage de dates
  const updateFilterDate = (startDate: Date | null, endDate: Date | null) => {
    if (startDate && endDate) {
      dispatch(setFilterDate({ start: startDate, end: endDate }));
    }
  };


  const { data: { filters: filter } = {}, error: filtersError, isFetching: isFetchingFilters, isLoading } = useGetFiltersQuery({
    startDate: dateRangeSelect.start !== undefined ? fDate(dateRangeSelect.start, "yyyy-MM-dd") : null,
    endDate: dateRangeSelect.end !== undefined ? fDate(dateRangeSelect.end, "yyyy-MM-dd") : null,
    device: filterDevice,
    filter: {
      countries: filterCountry,
      keywords: selectedKeyword
    }
  });


  const [initialFilterDispatched, setInitialFilterDispatched] = useState(false);

  useEffect(() => {
    if (filter?.countries && !initialFilterDispatched) {
      dispatch(setFilterCountry(filter.countries));
      setFilterCountryState(filter?.countries);
      setInitialFilterDispatched(true);
    }
  }, [filter, initialFilterDispatched, dispatch]);

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;


  const handleDeviceFilterChange = (value?: string) => {
    if (!value) return;

    if (value === "TOUT") {
      if (filterDevice.length === 3) {
        setFilterDeviceState([]);
      } else {
        setFilterDeviceState(["TABLET", "DESKTOP", "MOBILE"]);
      }
    } else {
      const isChecked = filterDevice.includes(value);
      if (isChecked) {
        setFilterDeviceState(filterDevice.filter((device: any) => device !== value));
      } else {
        setFilterDeviceState([...filterDevice, value]);
      }
    }
  };

  useEffect(() => {
    dispatch(setFilterDevice(filterDevice));
  }, [filterDevice, dispatch]);


  useEffect(() => {
    if (dateRangeSelect?.start && dateRangeSelect?.end) {
      dispatch(setFilterDate({
        start: dateRangeSelect.start,
        end: dateRangeSelect.end
      }));
    }

    dispatch(setFilterCountry(filter?.countries));
    setFilterDeviceState(["DESKTOP", "TABLET", "MOBILE"]);


  }, []);

  useEffect(() => {
    const keywords = filter?.keywords.map((item: any) => item.search_term) || []
    setSelectedKeywordState(keywords);
    setFilterKeywordState(filter?.keywords || []);
    dispatch(setFilterKeyword(keywords)); // Dispatchez l'action pour mettre à jour les mots-clés dans le store

  }, [filter])

  const handleCountryFilterChange = (value?: string) => {
    if (value !== undefined) {
      if (value === "all") {
        if (filterCountry.length === (filter?.countries?.length ?? 0)) {
          setFilterCountryState([]);
        } else {
          setFilterCountryState(filter?.countries ?? []);
          dispatch(setFilterCountry(filter?.countries));
        }
      } else {
        const isChecked = filterCountry.includes(value);

        if (isChecked) {
          // Deselect the country if already selected
          setFilterCountryState(filterCountry.filter((country: string) => country !== value));
          dispatch(setFilterCountry(filterCountry.filter((country: string) => country !== value)));
        } else {
          // Select the country if not selected
          setFilterCountryState([...filterCountry, value]);
          dispatch(setFilterCountry([...filterCountry, value]));
        }
      }
    }
  };

  const handleKeywordFilterChange = (keyword: any) => {
    setSelectedKeywordState(prevSelected => {
      const updatedKeywords = prevSelected.includes(keyword.search_term)
        ? prevSelected.filter(term => term !== keyword.search_term)
        : [...prevSelected, keyword.search_term];

      dispatch(setFilterKeyword(updatedKeywords)); // Dispatchez l'action pour mettre à jour les mots-clés dans le store

      return updatedKeywords;
    });
  };

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <>
        <Button onClick={() => console.log("test")} sx={{ marginRight: 1 }} variant="contained" color="inherit">
          <Iconify icon="solar:reset-alt" width={24} /> Reset all filters
        </Button>










        <FormControl sx={{ flexShrink: 0, width: { xs: 1, md: 200 } }}>
          <InputLabel>Keyword</InputLabel>
          <Select
            label="Select keyword"
            labelId="keyword-label"
            multiple={true}
            renderValue={(selected) => {
              if (selected.length === filter?.keywords?.length) {
                return 'All';
              }
            }}
            value={filterKeyword}
            sx={{ height: '40px', marginRight: 1 }}
          >
            <div style={{ padding: 10, display: 'flex', flexDirection: "column" }}>
              <MenuItem key="all" value="all">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedKeyword.length === filter?.keywords?.length}
                      onChange={() => setSelectedKeywordState([])} />
                  }
                  label="All"
                />
              </MenuItem>

              <hr style={{ width: '100%' }} />

              <InfiniteScrollKeywords
                keywords={filterKeyword}
                selectedKeywords={selectedKeyword}
                onKeywordChange={(it) => handleKeywordFilterChange(it)}
              />
            </div>
          </Select>
        </FormControl>


















        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>Country</InputLabel>
          <Select
            label="Select country"
            labelId="country-label"
            multiple={true}
            value={filterCountry}
            renderValue={() => filterCountry.map((value: any) => value).join(', ')}
            sx={{ height: '40px', marginRight: 1 }}
          >
            <div style={{ padding: 10, display: 'flex', flexDirection: "column" }}>
              <MenuItem key="all" value="all">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterCountry.length === filter?.countries?.length}
                      onChange={() => handleCountryFilterChange("all")}
                    />
                  }
                  label="All"
                />
              </MenuItem>
              <hr style={{ width: '100%' }} />

              {filter?.countries?.map((country: string, index: number) => (
                <MenuItem key={index} value={country}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterCountry.includes(country)}
                        onChange={() => handleCountryFilterChange(country)}
                      />
                    }
                    label={country}
                  />
                </MenuItem>
              ))}
            </div>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>Device</InputLabel>
          <Select
            label="Select device"
            labelId="device-label"
            multiple={true}
            value={filterDevice}
            renderValue={() =>
              filterDevice.map((value: any) =>
                value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
              ).join(', ')
            }
            sx={{ height: '40px', marginRight: 1 }}
          >
            <div style={{ padding: 10, display: 'flex', flexDirection: "column" }}>
              <MenuItem key={0} value={0}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={0}
                      checked={filterDevice.length === 3}
                      onChange={() => handleDeviceFilterChange("TOUT")}
                    />
                  }
                  label="All"
                />
              </MenuItem>

              <hr style={{ width: '100%' }} />

              <FormControlLabel
                control={
                  <Checkbox
                    value={1}
                    onChange={() => handleDeviceFilterChange("TABLET")}
                    checked={filterDevice.includes("TABLET")}
                  />
                }
                label="Tablet"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={2}
                    onChange={() => handleDeviceFilterChange("MOBILE")}
                    checked={filterDevice.includes("MOBILE")}
                  />
                }
                label="Mobile"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={3}
                    onChange={() => handleDeviceFilterChange("DESKTOP")}
                    checked={filterDevice.includes("DESKTOP")}
                  />
                }
                label="Desktop"
              />
            </div>

          </Select>
        </FormControl>



        <DateRangeSelect
          startDate={dateRangeSelect.start || new Date()}
          endDate={dateRangeSelect.end || new Date()}
          selected={
            dateRangeSelect.selected === "custom"
              ? `${fDate(dateRangeSelect.start)} - ${fDate(dateRangeSelect.end)}`
              : (dateRangeSelect.preset as any).displayName
          }
          onChangeEndDate={(endDate: Date | null) => {
            setEndDate(endDate)
            dateRangeSelect.onChangeEndDate(endDate);
            updateFilterDate(filterStore?.start, endDate);
          }}
          onChangeStartDate={(startDate: Date | null) => {
            dateRangeSelect.onChangeStartDate(startDate);
            updateFilterDate(startDate, endDate);
          }}
          onChangePreset={(preset) => {
          }}
          onChangeTimeStep={dateRangeSelect.onChangeTimeStep}
          preset={"last_30d"}
          timeStep={dateRangeSelect.step as any}
        />




      </>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <WorkspacePoppver />
        <LanguagePopover />

        <NotificationsPopover />

        <SettingsButton />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
