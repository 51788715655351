// components
import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: string[]; // Ajout de la prop labels
};

export default function ChartColumnStacked({ series, labels }: Props) {
  const chartOptions = useChart({
    chart: {
      stacked: true,
      zoom: {
        enabled: true,
      },
    },
    labels: labels || [],
    legend: {
      itemMargin: {
        vertical: 8,
      },  
      position: 'top',
      offsetY: 20,
    },
    fill: {
      colors: ['#43a047', '#1e88e5', '#b7b7b7']
    },
    colors: ['#43a047', '#1e88e5', '#b7b7b7'],
    plotOptions: {
      bar: { barHeight: '80%', columnWidth: '50%', borderRadius: 0 },
    },
    stroke: {
      show: false,
    },
    xaxis: {
      
    },
    yaxis: [
      {
          labels: { formatter(value) { return (value / 1000).toFixed(3) } }
      },
    ]
  });

  return <Chart dir="ltr" type="bar" series={series} options={chartOptions} height={350} />;
}
