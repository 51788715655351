import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { QueryAdAccount, QueryAttribution, QueryDateRange, QueryWorkspace } from '../shared/query';
import { DetailResponse, TopCopyResponse, TopCreativeResponse, TopLandingResponse, TopVideoResponse } from './models';

type QueryArg = QueryDateRange & QueryAdAccount & QueryWorkspace & QueryAttribution;
interface ByCreative {
  creativeId: number;
}

interface Limit {
  limit: number;
}

const adAccounts = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: CROSS_API_URL + '/ad_creatives',
  }),
  reducerPath: 'api.ad_creatives',
  tagTypes: ['ad_creatives'],
  endpoints: (builder) => ({
    topCreatives: builder.query<TopCreativeResponse, QueryArg & Limit>({
      query: (args) => {
        let url = `/${args.adAccountId}/top_creatives?start_date=${args.start}&end_date=${args.end}&workspace=${args.workspace}&limit=${args.limit}`;
        url += ("&attribution_window=" + args.attributionWindow.join("&attribution_window="))
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    topVideos: builder.query<TopVideoResponse, QueryArg & Limit>({
      query: (args) => {
        let url = `/${args.adAccountId}/top_videos?start_date=${args.start}&end_date=${args.end}&workspace=${args.workspace}&limit=${args.limit}`;
        url += ("&attribution_window=" + args.attributionWindow.join("&attribution_window="))
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    topCopy: builder.query<TopCopyResponse, QueryArg & Limit>({
      query: (args) => {
        let url = `/${args.adAccountId}/top_copy?start_date=${args.start}&end_date=${args.end}&workspace=${args.workspace}&limit=${args.limit}`;
        url += ("&attribution_window=" + args.attributionWindow.join("&attribution_window="))
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    topLandings: builder.query<TopLandingResponse, QueryArg & Limit>({
      query: (args) => {
        let url = `/${args.adAccountId}/top_landing?start_date=${args.start}&end_date=${args.end}&workspace=${args.workspace}&limit=${args.limit}`;
        url += ("&attribution_window=" + args.attributionWindow.join("&attribution_window="))
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    creativeDetail: builder.query<DetailResponse, QueryArg & ByCreative>({
      query: (args) => {
        let url = `/${args.adAccountId}/creative/${args.creativeId}/detailed?start_date=${args.start}&end_date=${args.end}&workspace=${args.workspace}`;
        url += ("&attribution_window=" + args.attributionWindow.join("&attribution_window="))
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
  }),

});

export const { useTopCreativesQuery, useCreativeDetailQuery, useTopCopyQuery, useTopVideosQuery, useTopLandingsQuery } = adAccounts;
export default adAccounts;
