import { Button, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useGetProvidersQuery } from "src/context/api/social";
import { useGetWorkspacesQuery } from "src/context/api/workspaces";
import { useBoolean } from "src/hooks/use-boolean";
import WorkspacePoppver from "src/layouts/_common/workspace-popover";
import Connect from "src/sections/overview/datasources/connect-form";
import QuickConnectDatasource from "src/sections/workspaces/quick-connect-datasource";

export default function Workflow() {
    const { data: workspaces, ...workspaceQuery } = useGetWorkspacesQuery(null);

    if (workspaces) {

        return (
            <Dialog open={true}>
                <DialogTitle>
                    Select a workspace to continue
                </DialogTitle>
                <DialogContent sx={{ padding: 3 }}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                        <WorkspacePoppver />
                    </Stack>
                </DialogContent>
            </Dialog>
        )
    }

}