import Chart, { useChart } from 'src/components/chart';

type Props = {
  series: {
    name: string;
    type: string;
    data: number[];
  }[];
  labels: string[]; // Ajout de la prop labels
};

export default function ChartMixed({ series, labels }: Props) {
  const chartOptions = useChart({
    chart: {
      zoom: {
        enabled: true,
      }
    },
    stroke: {
      width: [0, 2, 3],
    },
    plotOptions: {
      bar: { columnWidth: '50%', borderRadius: 0 },
    },
    fill: {
      type: ['solid', 'solid'],
      colors: ['#ffb102', '#834094']
    },
    labels: labels || [],
    colors: ["#ffb102", "#834094"],
    markers: {
      size: 6
    },
    yaxis: [
      {
        title: { text: 'Ad Cost' },
        labels: { formatter(value) { return (value / 1000).toFixed(0) + "k" } }
      },
      {
        title: { text: 'Blended CTR' },
        opposite: true,
        labels: { formatter(value) { return value.toFixed(0) + "%" } }
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value: number, { seriesIndex, w }) => {
          if (typeof value !== 'undefined') {
            const seriesType = w.config.series[seriesIndex].type;
            if (seriesType === "line") {
              return value.toFixed(0) + "%";
            } else {
              return `$${value.toFixed(0)}`;
            }
          }
          return value;
        },
      },
    }
    
  });

  return <Chart dir="ltr" type="line" series={series} options={chartOptions} height={350} />;
}
