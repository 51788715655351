import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useForm } from 'react-hook-form';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useLocales } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import * as Yup from 'yup';

export default function ForgotPassword() {
  const { t } = useLocales();
  const schema = Yup.object({
    email: Yup.string().required(t('auth.form.email_must_be_filled')),
  });
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });
  const {
    formState: { isSubmitting },
  } = methods;
  return (
    <FormProvider methods={methods}>
      <Stack spacing={2} sx={{ mb: 5 }}>
        <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
          <Typography variant="h4">{t('auth.form.forgot')}</Typography>

          <Stack direction="row" spacing={0.5}>
            <Link href={'/auth/signin'} component={RouterLink} variant="subtitle2">
              {t('auth.form.back_to_signin')}
            </Link>
          </Stack>
        </Stack>
        <RHFTextField name="email" label={t('auth.form.signin.Email')} />
        <LoadingButton
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          fullWidth
          loading={isSubmitting}
        >
          {t('auth.form.resquest_reset')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
