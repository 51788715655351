import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import store, { RootState } from 'src/context/store';

export const useAttributionOptions = <T extends keyof RootState["attributionSettings"]>(option: T) => {
  const selector = createSelector(
    [(state: RootState) => state.attributionSettings[option]],
    (option) => option
  );

  return useSelector(selector)
}