import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";

const appSlice = createSlice({
    name: "appSettings",
    initialState,
    reducers: {
        setCurrentWorkspace: (state, action) => {
            state.currentWorkspace = action.payload;
        },
        resetCurrentWorkspace: (state) => {
            state.currentWorkspace = null;
        }
    },
});

export default appSlice.reducer;

export const { setCurrentWorkspace, resetCurrentWorkspace } = appSlice.actions;
